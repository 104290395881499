


import React from 'react';
import Modal from 'react-modal';
import './modal-styles.css'


const customStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    //border: '1px solid #ccc',
    border: 'none',
    borderRadius: '1rem',
    maxWidth: '400px', // Adjust as needed
    backgroundColor: '#73AFA4', 
    width: '75%',
    height: '50%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 1000, // Ensure the modal is on top
    height: '120vh',
  },
};

const ModalComponent = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal" // For accessibility
      closeTimeoutMS={500}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;



























// import React, { useState } from "react";
// import Modal from "react-modal";
// import './modal-styles.css'
// import { forwardRef } from 'react';
// import { useEffect } from 'react';
// import ModalContents from './ModalContents'

// const ModalComponent = forwardRef((props, ref) => {

//     const { data } = props
//     const { isActive } = props
//     useEffect(() => {
//         if (isActive == true) {
//             return
//             // setTimeout(() => { setButtonOpacityVariable('50%') }, 100)
//         }
//         if (isActive == false) {
//             return
//             // setButtonOpacityVariable('0%')
//         }
//     }, [isActive]);

//     const [isOpen, setIsOpen] = useState(false);
//     function toggleModal() {
//         //console.log('toggle')
//         setIsOpen(!isOpen);
//     }

//     React.useImperativeHandle(ref, () => ({
//         childFunction
//     }));

//     function childFunction() {
//         // Function logic in the child component
//         // console.log("Child function called from parent");
//         toggleModal()
//     }

//     const wineDetails = props.data;

//     if (props.render == true) {
//         return (
//             <div className="modalApp" style={{ width: '100%', height: '100%', opacity: '0%', }}>
//                 <button style={{
//                     color: 'maroon',
//                     fontSize: '1rem',
//                     fontWeight: 'bold',
//                     position: 'relative',
//                     width: '100%',
//                     height: '100%',
//                     padding: '0rem',
//                     // marginTop: '5rem',
//                     //opacity: buttonOpacityVariable,
//                     // transition: 'opacity 1s',

//                 }} onClick={toggleModal}>VIEW DETAILS BUTTON</button>

//                 <div >
                
//                     <Modal
//                         isOpen={isOpen}
//                         onRequestClose={toggleModal}
//                         //contentLabel="modal_dialog"
//                         className="modalClass"
//                         overlayClassName="overlayClass"
//                         closeTimeoutMS={500}
//                     >
//                         <ModalContents onRequestClose={toggleModal} ></ModalContents>
//                     </Modal>

//                 </div>

//             </div>
//         )
//     } else {
//         return (
//             <div></div>
//         )
//     }

// })

// export default ModalComponent;


