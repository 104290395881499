// ==========================================
//               IMPORTS
// ==========================================

// REDUX
import { store } from '../../store.js';

// REDUX ACTIONS
import a02_SelectCourse from "../../actions/a02_SelectCourse"

const devModeBool = true
export const selectCourse = async (data) => {

    let searchTerm = data.course_id

    try {
        console.log('data:', data);

        

        const response = await fetch("/api/getcoursemodules", { // Note: No query in the URL
            method: "POST",
            // headers: {
            //   "Content-Type": "application/json",
            //   Authorization: `Bearer ${token}`,
            // },
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: searchTerm,
              // Add any other search filters here if needed
            }),
          });

          const responseData = await response.json();
            await console.log(responseData)

            const responseDataCourseObject = {
              responseData: responseData,
              courseObject: data,
            }


          //   courseObject = {
          //     courseTitle: courseTitle,
          //     course_id: course_id
          // }


        //   const response2 = await fetch("/api/getcoursemodules", { 
        //     method: "POST",
        //     body: JSON.stringify({
        //       query: 'searchTerm',
        //     }),
        //   });



          




        await store.dispatch(a02_SelectCourse(responseDataCourseObject));



    } catch (error) {
        if (devModeBool === true) {
            console.error('Error:', error);
        }
    }
}




















// const searchCourses = async () => {
//     try {
//       const token = localStorage.getItem("access_token");
  
//       const response = await fetch("/api/courses/search/", { // Note: No query in the URL
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           query: searchTerm,
//           // Add any other search filters here if needed
//         }),
//       });
  
//       if (!response.ok) {
//         throw new Error("Network response was not ok.");
//       }
//       const data = await response.json();
//       console.log(data); // Log the response from Django
//     } catch (error) {
//       console.error("There was a problem with the fetch operation:", error);
//     }
//   };
  
  // ...
  // Button or event handler to trigger the searchCourses function
  























// export const fetchDataAfterPageLoad = async (fetchEndpointURL) => {

//     if (devModeBool === true) {
//         console.log('Component mounted, if this happens twice check strict mode');
//     }

//     //console.log('fetchDataAfterPageLoad')
//     try {
//         const response = await fetch(fetchEndpointURL);
//         const data = await response.json();
//         if (devModeBool === true) {
//              console.log('FETCH_DATA SUCCESS ----- ', data)
//         }

//         await store.dispatch(a01_FetchLibraryDataSuccess(data));

//         /////await store.dispatch(a02_CreateDataSearchObjects(data));
//         // setLoading(false);

//     } catch (error) {
//         if (devModeBool === true) {
//             console.error('Error:', error);
//             console.log('FETCH_DATA ----- ERROR')
//         }
//     }

// }

