import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';


import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";


import { Avatar } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import CourseLessonRender from './CourseLessonRender'

import '../../fonts/font.css'


const MENU_BACKGROUND_COLOR = '#73AFA4'
const MENU_TEXT_COLOR = 'black'





const StyledAddIcon = () => (
    <div style={{
        width: '100%', display: 'flex', justifyContent: 'center',
        padding: '0rem', margin: '0rem',
    }}>
        <Add
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: MENU_TEXT_COLOR,
                padding: '0rem', margin: '0rem',
            }}
        >
        </Add>
    </div>
);

const StyledRemoveIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Remove
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: MENU_TEXT_COLOR,
                padding: '0rem', margin: '0rem',
            }}
        >
        </Remove>
    </div>
);




















const SingleModule = (props) => {

    // Extract data from props
    const { data } = props;
    console.log('SingleModule props', props)

    let numberLessonsInModule = data.lessons.length
    console.log('data.lessons', data.lessons)


    const [openStatus, setOpenStatus] = useState(false);










    return (

        <div
            style={{
                margin: '0rem',
            }}
        >
            <style>
                {`
                    .fade {
                        opacity: 1;
                        transition: opacity 0.3s ease;
                    }
                    .visible {
                        opacity: 1;
                    }
                    .hidden {
                        opacity: 0;
                    }
                `}
            </style>

            {/* <Card sx={{
                minWidth: 300,
                backgroundColor: MENU_BACKGROUND_COLOR,
                color: MENU_TEXT_COLOR,
                borderRadius: '0px',
                opacity: '100%',
                margin: '0rem',
                marginTop: '0.5rem',
                padding: '0rem',
                //borderColor: 'black',
                border: 'none',
                //borderRadius: '0.5rem',
                fontSize: '2rem',
                //boxShadow: '2px 2px 5px rgba(03, 44, 88 0.5)'
            }}> */}







            <CardHeader
                onClick={() => setOpenStatus(!openStatus)}

                // avatar={
                //     <Avatar
                //         sx={{
                //             width: '6rem',
                //             height: '4rem',
                //             borderRadius: '1rem',
                //             backgroundColor: '#D1EEE8', // '#BBE7E0'
                //         }}>

                //         {isLocked && (

                //             <img style={{
                //                 width: '1.5rem',
                //                 height: '1.5rem',
                //             }} src={logo_icon} alt="Logo" />
                //         )}

                //         <img
                //             style={{
                //                 width: "3rem",
                //                 height: "3rem",
                //             }}
                //             src={selectedLogo}
                //             alt="Logo"
                //         />




                //     </Avatar>

                // }


                aria-label="expand"
                size="medium"

                titleTypographyProps={{
                    fontFamily: 'imported-font-varela',
                    fontSize: "1.3rem", fontWeight: 'bold', textAlign: 'left',
                }}
                title={data.module_title}
                action={
                    <IconButton
                        onClick={() => setOpenStatus(!openStatus)}
                        aria-label="expand"
                        size="large"
                        sx={{
                            width: '4rem',
                            height: '4rem',
                        }}
                    >
                        {openStatus ? <StyledRemoveIcon />
                            : <StyledAddIcon />}

                    </IconButton>
                }
            >
            </CardHeader>


            <div style={{
                margin: '1rem',
                backgroundColor: '#8c8bb8', // 97cbc2 BB98D9  8c8bb8
                padding: '10px',
                borderRadius: '0.5rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'space-between',

            }}>
                <div>
                    <p style={{ fontSize: '1.1rem', fontWeight: 'bold', margin: '0px' }}>Status: Incomplete</p>
                </div>
                <div>
                    <p style={{ fontSize: '1.1rem', fontWeight: 'bold', margin: '0px' }}>Lesson Count: {numberLessonsInModule}</p>
                </div>


            </div>



            <div style={{
                padding: '0rem', margin: '0rem', border: '0rem'
            }}>





                <Collapse in={openStatus} timeout="auto"
                    unmountOnExit

                    style={{
                        padding: '0rem', margin: '0rem', border: '0rem'
                    }}

                >
                    <CardContent
                        style={{
                            padding: '0rem', margin: '0rem', border: '0rem',
                        }}
                    >
                        <Container

                            sx={{
                                height: 'auto',
                                // lineHeight: 0,
                                width: '100%',
                                padding: '0rem',
                                margin: '0rem',
                            }}>

                            <div
                                style={{
                                    padding: '0rem',
                                    paddingTop: '0rem',
                                    margin: '0rem',
                                }}
                            >




                                <CourseLessonRender data={data} ></CourseLessonRender>




                            </div>
                        </Container>
                    </CardContent>
                </Collapse>

                <div

                    style={{
                        backgroundColor: '#A467CC', // A467CC BB98D9 9B59B6
                        width: '100%', height: '3px',
                        borderRadius: '2px',
                        opacity: '50%',
                    }}

                >

                </div>

            </div>


            {/* </Card> */}


        </div >
    );












};

export default SingleModule;

