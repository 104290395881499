


export default function exportedFunction(state = {}, action) {

    if (action.type === 'FETCH_COMMUNITY_TOPIC_SUCCESS') {

        console.log('COMMUNITY_TOPIC REDUCER', action.payload)

        let communityTopics = action.payload

        return {
            communityTopics: communityTopics,
            topicPosts: state.topicPosts,
            postReplies: state.postReplies,
        };
    }


    if (action.type === 'FETCH_POSTS') {

        console.log('FETCH_POSTS REDUCER', action.payload)

        let topicPosts = action.payload





          
        return {
            communityTopics: state.communityTopics,
            topicPosts: topicPosts,
            postReplies: state.postReplies,
        };
    }


    if (action.type === 'FETCH_POST_REPLIES') {

        console.log('FETCH_POST_REPLIES REDUCER', action.payload)

    //     let postReplies = state.postReplies
    //    postReplies[action.payload.postId] = action.payload.data

        let newPostReplies = {
            ...state.postReplies, // Copy all existing post replies
            [action.payload.postId]: action.payload.data // Add the new reply using computed property syntax
        }


        return {
            communityTopics: state.communityTopics,
            topicPosts: state.topicPosts,
            postReplies: newPostReplies,
        };
    }


    // if (action.type === 'FETCH_POST_REPLIES') {
    //     console.log('FETCH_POST_REPLIES REDUCER', action.payload);
    
    //     // Create a new object with the updated postReplies
    //     return {
    //         ...state, // Copy all existing properties from the state
    //         postReplies: {
    //             ...state.postReplies, // Copy all existing post replies
    //             [action.payload.postId]: action.payload.data // Add the new reply using computed property syntax
    //         },
    //     };
    // }





    return state;
}



