import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';


// REDUX
import { connect } from 'react-redux';
import { COURSE_DATA } from '../../redux/selectors';
import { LIBRARY_DATA } from '../../redux/selectors';
import { USER_DATA } from '../../redux/selectors';


const Purchase = (props) => {




    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    const navigate = useNavigate();
    const handleButtonClick = (pathURL) => {
        navigate(pathURL);
    };

    // if (props.LIBRARY_DATA_FROM_SELECTOR == undefined)
    //     return <Navigate to='/library' />;
    // if (props.COURSE_DATA_FROM_SELECTOR == undefined)
    //     return <Navigate to='/library' />;
    // if (props.USER_DATA_FROM_SELECTOR == undefined)
    //     return <Navigate to='/library' />;








    let subscriptionPlans 
    try {
        subscriptionPlans = props.LIBRARY_DATA_FROM_SELECTOR.subscriptionPlans
        console.log('subscriptionPlans ', subscriptionPlans)
    }
    catch {
        return <Navigate to='/library' />;
    }


    let subscriptionPlanIdArray 
    try {
        subscriptionPlanIdArray = props.COURSE_DATA_FROM_SELECTOR.courseObject.subscription_plans
        console.log('subscriptionPlanIdArray ', subscriptionPlanIdArray)
    }
    catch {
        return <Navigate to='/library' />;
    }


















    let subscriptionOptionsRenderArray = []

    subscriptionPlans.forEach(subscription_item => {
        console.log("subscription_item ", subscription_item)
        let subscription_item_id = subscription_item.id
        console.log("subscription_item_id ", subscription_item_id)
        if (subscriptionPlanIdArray.includes(subscription_item_id)) {
            console.log('ITEM INCLUDED')
            subscriptionOptionsRenderArray.push(subscription_item)
        }

        // if (item.id === targetId) {
        //     console.log("Found item:", item);
        //     // Do something with the item
        // }


    });

    console.log("subscriptionOptionsRenderArray ", subscriptionOptionsRenderArray)


    // subscriptionOptionsRenderArray.stripe_reference_key


    const user_email = props.USER_DATA_FROM_SELECTOR.user.email











    let MENU_BACKGROUND_COLOR = '#97CBC2' // '#D1EEE8' 
    let MENU_AVATAR_COLOR = '#73afa4'
    let MENU_BUTTON_COLOR = "#73afa4"







    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Purchase page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>


                    <div
                        onClick={() => handleButtonClick("/library")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "9rem",
                            height: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom: '2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            Back
                        </p>
                    </div>



                    <h3> Purchase Page</h3>


                    <div style={{
                        margin: '2rem',
                    }}></div>


                    <h3 style={{
                        textAlign: 'center',
                        fontSize: '1.8rem',
                        fontWeight: 'bold',
                        fontFamily: 'imported-font-varela',
                    }}>
                        {props.COURSE_DATA_FROM_SELECTOR.courseObject.courseTitle}
                    </h3>









                    {subscriptionOptionsRenderArray.map((item, index) => (

                        <div
                            key={`displayItem_${index}`}
                            style={{
                                marginBottom: '1rem',
                                padding: '2rem',
                                borderRadius: '0.5rem',
                                backgroundColor: MENU_BACKGROUND_COLOR,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {/* <h3>item {index}</h3>
                            <p>key {subscriptionOptionsRenderArray[index]['stripe_reference_key']}</p> */}
                            <div>
                                <h3
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >{item.name}</h3>
                                <h3
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >${item.price}</h3>

                                <form
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}

                                    // action="http://localhost:4242/create-checkout-session" method="POST">
                                    action="https://birthlounge.dev/stripe/create-checkout-session" method="POST">

                                    <input type="hidden"
                                        name="lookup_key"
                                        value={subscriptionOptionsRenderArray[index]['stripe_reference_key']} />

                                    <input type="hidden" name="user_id" value={user_email} />

                                    <input type="hidden" name="product_type"
                                        value={subscriptionOptionsRenderArray[index]['id']} />

                                    <button
                                        style={{
                                            margin: '1rem',
                                            padding: '1rem',
                                            borderRadius: '1rem',
                                            backgroundColor: MENU_BUTTON_COLOR,
                                            border: 'none',
                                            borderColor: 'black',
                                            outline: 'none',
                                            fontSize: '1.3rem',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        }}
                                        id="checkout-and-portal-button" type="submit">
                                        Checkout
                                    </button>

                                </form>
                            </div>



                        </div>


                    ))}




                    <div style={{
                        paddingBottom: '10rem',
                    }}></div>










                    {/* <div>
                        <form action="http://localhost:4242/create-checkout-session" method="POST">
                            <input type="hidden" name="lookup_key" value='price_1PdNIrKE0e1CFeGpgTKHfc1U' />
                            <button id="checkout-and-portal-button" type="submit">
                                Checkout
                            </button>
                        </form>
                    </div> */}













                    <BottomBar></BottomBar>

                </div>
            )
            }
        </Layout >
    );
};

// export default Purchase;

const mapStateToProps = (state) => ({
    COURSE_DATA_FROM_SELECTOR: COURSE_DATA(state),
    LIBRARY_DATA_FROM_SELECTOR: LIBRARY_DATA(state),
    USER_DATA_FROM_SELECTOR: USER_DATA(state),
});

export default connect(mapStateToProps)(Purchase);


