import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { checkAuth } from 'features/user';

import HomePage from 'containers/HomePage';
// import DashboardPage from 'containers/DashboardPage';

import LoginPage from 'containers/LoginPage';
import RegisterPage from 'containers/RegisterPage';

// import DashboardPage from './pages/dashboard/Dashboard';
import Library from './pages/library/Library';
import Home from './pages/home/Home';
import Course from './pages/course/Course';
import CourseModule from './pages/coursemodule/CourseModule';
import Podcast from './pages/podcast/Podcast';
import Support from './pages/support/Support';
import Community from './pages/community/Community';
import SampleModalPage from './pages/modal/SampleModalPage';
import Lesson from './pages/lesson/Lesson';

import StripePage from './pages/stripe/StripePage';
import StripeSuccess from './pages/stripe/StripeSuccess';

import Purchase from './pages/purchase/Purchase';



import { useState } from 'react';
import { useLocation } from 'react-router-dom';



const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkAuth());
	}, []);





	// const location = useLocation();
	// const [initialPath, setInitialPath] = useState(location.pathname);
  
	// useEffect(() => {
	//   // Check if the current path is not the home page and if it's not a navigation action
	//   if (location.pathname !== '/home' && location.pathname !== initialPath && location.pathname !== '/') {
	// 	setInitialPath(location.pathname); // Update initialPath to the current path for subsequent navigation within the app. 
	// 	window.location.href = '/home'; // Redirect to the home page
	//   }
	// }, [location]);

















	return (
		<Router>
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/home' element={<Home />} />
				<Route path='/login' element={<LoginPage />} />
				<Route path='/register' element={<RegisterPage />} />
				<Route path='/library' element={<Library />} />

				<Route path='/course' element={<Course />} />
				<Route path='/coursemodule' element={<CourseModule />} />
				<Route path='/podcast' element={<Podcast />} />
				<Route path='/support' element={<Support />} />
				<Route path='/community' element={<Community />} />

				<Route path='/modal' element={<SampleModalPage />} />
				<Route path='/lesson' element={<Lesson />} />

				<Route path='/stripe' element={<StripePage />} />
				<Route path='/stripesuccess' element={<StripeSuccess />} />

				<Route path='/purchase' element={<Purchase />} />

			</Routes>
		</Router>
	);
};

export default App;
