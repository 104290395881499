import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';


import podcastIcon from './img/podcasts.apple.com.png'
import blogIcon from './img/tbhdoula.blogspot.com.png'
import facebookIcon from './img/facebook.png'
import youtubeIcon from './img/youtube.png'
import pinterestIcon from './img/pinterest.png'
import instagramIcon from './img/instagram.png'


import '../../fonts/font.css'




const Support = () => {

    const { isAuthenticated, user, loading } = useSelector(state => state.user);







    const handleClick = (redirectUrl) => {

        const urlObject = {
            podcast: 'https://podcasts.apple.com/us/podcast/the-birth-lounge-podcast/id1346872486',
            blog: 'http://blog.thebirthlounge.com/',
            facebook: 'https://www.facebook.com/tranquilitybyhehe/',
            youtube: 'https://www.youtube.com/channel/UCAlpWUtJ2BnAkRnok4x50nw/',
            pinterest: 'https://www.pinterest.com/hehestewart/',
            instagram: 'https://www.instagram.com/tranquilitybyhehe/',
        }


        window.location.href = urlObject[redirectUrl]; // Replace with your external URL
    };









    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Support Page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div >
                    {/* <h3> Support</h3> */}




                    <div style={{
                        //textAlign: 'center',
                        paddingBottom: '1rem',
                        //fontFamily: 'imported-font-varela',

                    }}>
                        <h3
                            style={{
                                fontSize: '2rem',
                                fontWeight: 'bold',
                            }}
                        >Contact</h3>
                    </div>





                    <div style={{ textAlign: 'center' }}>


                        <div style={{ fontSize: '1.5rem', padding: '1rem' }}>
                            <h3 style={{fontWeight: 'bold' }}>
                                Email us today!
                            </h3>
                            <p style={{  fontFamily: 'imported-font-varela', }}>
                                tranquilitybyhehe@gmail.com
                            </p>
                        </div>


                        <div style={{  padding: '1rem' }}>
                            <h3 style={{ fontSize: '1.5rem',  fontWeight: 'bold' }}>
                                Give us a call!
                            </h3>
                            <p style={{  fontFamily: 'imported-font-varela', fontSize: '1.3rem',}}>
                                Boston, Massachusetts, United States
                            </p>
                            <p style={{  fontFamily: 'imported-font-varela', fontSize: '1.5rem',}}>
                                601.516.0829
                            </p>
                        </div>

                        <div style={{ fontSize: '1.3rem', padding: '1rem' }}>
                            <h3 style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                                Connect with us on social media!
                            </h3>

                            <p style={{  fontFamily: 'imported-font-varela', fontSize: '1.3rem', }}>
                                Stay up-to-date with what we are doing and the latest topics in pregnancy, birth, and parenthood. (links below)
                            </p>
                        </div>


                    </div>



                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginBottom: '5rem',
                    }}>




                        <div
                            onClick={() => {
                                handleClick('podcast')
                            }}

                            style={{
                                height: '4rem',
                                width: '4rem',
                                padding: '0.5rem',
                                backgroundColor: '#bb98d9',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${podcastIcon})`,
                                    backgroundSize: 'contain',
                                    height: '3rem',
                                    width: '3rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>



                        <div

                            onClick={() => {
                                handleClick('blog')
                            }}


                            style={{
                                height: '3.5rem',
                                width: '3.5rem',
                                padding: '0.5rem',
                                backgroundColor: '#97cbc2',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${blogIcon})`,
                                    backgroundSize: 'contain',
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>







                        <div

                            onClick={() => {
                                handleClick('facebook')
                            }}


                            style={{
                                height: '4rem',
                                width: '4rem',
                                padding: '0.5rem',
                                backgroundColor: '#bb98d9',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${facebookIcon})`,
                                    backgroundSize: 'contain',
                                    height: '3rem',
                                    width: '3rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>



                        <div

                            onClick={() => {
                                handleClick('youtube')
                            }}

                            style={{
                                height: '3.5rem',
                                width: '3.5rem',
                                padding: '0.5rem',
                                backgroundColor: '#97cbc2',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${youtubeIcon})`,
                                    backgroundSize: 'contain',
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>






                        <div

                            onClick={() => {
                                handleClick('pinterest')
                            }}

                            style={{
                                height: '4rem',
                                width: '4rem',
                                padding: '0.5rem',
                                backgroundColor: '#bb98d9',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${pinterestIcon})`,
                                    backgroundSize: 'contain',
                                    height: '3rem',
                                    width: '3rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>



                        <div

                            onClick={() => {
                                handleClick('instagram')
                            }}

                            style={{
                                height: '3.5rem',
                                width: '3.5rem',
                                padding: '0.5rem',
                                backgroundColor: '#97cbc2',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',
                            }}>
                            <div
                                style={{
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${instagramIcon})`,
                                    backgroundSize: 'contain',
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    padding: '1rem',
                                }}
                            ></div>
                        </div>







                    </div>






                    <div style={{ fontSize: '1.3rem', marginBottom: '12rem', backgroundColor: '#d1eee8', height: '100%', borderRadius: '1rem', padding: '1rem', }}>
                        <h2 style={{ fontWeight: 'bold', padding: '1rem', textAlign: 'center', }}>
                            Add contact form which sends email here:
                        </h2>
                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>Email address</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>

                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>Full name</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>


                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>Where are you birthing?</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>


                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>Your estimated due date</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>


                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>Where do you live?</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>


                        <p style={{
                            padding: '0rem',
                            margin: '0rem',
                        }}>How did you find us?</p>
                        <input style={{
                            backgroundColor: "#e7f4f0",
                            padding: '0rem',
                            margin: '0rem',
                            width: '100%',
                            outline: 'none',
                            marginBottom: '1rem'
                        }}></input>



                        <p>What services are you interested in? *</p>

                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <input
                                style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginRight: '1rem',
                                    accentColor: '#bb98d9',
                                    borderRadius: '0.5rem'
                                }}
                                type="checkbox"
                            />
                            Fertility Support
                        </label>


                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <input
                                style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginRight: '1rem',
                                    accentColor: '#bb98d9',
                                    borderRadius: '0.5rem'
                                }}
                                type="checkbox"
                            />
                            Birth Support
                        </label>



                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <input
                                style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginRight: '1rem',
                                    accentColor: '#bb98d9',
                                    borderRadius: '0.5rem'
                                }}
                                type="checkbox"
                            />
                            Postpartum Support
                        </label>



                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <input
                                style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginRight: '1rem',
                                    accentColor: '#bb98d9',
                                    borderRadius: '0.5rem'
                                }}
                                type="checkbox"
                            />
                            Birth and Postpartum Support
                        </label>



                        <label
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <input
                                style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginRight: '1rem',
                                    accentColor: '#bb98d9',
                                    borderRadius: '0.5rem'
                                }}
                                type="checkbox"
                            />
                            Virtual Services
                        </label>




                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',

                            }}>

                            <div style={{
                                backgroundColor: '#bb98d9',
                                margin: '2rem',
                                alignItems: 'center',
                                borderRadius: '1rem',
                                cursor: 'pointer',

                            }}>
                                <p style={{
                                    padding: '1rem',
                                    margin: '0rem',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                }}>SEND</p>
                            </div>
                        </div>
                    </div>





                    <BottomBar></BottomBar>

                </div>
            )
            }
        </Layout >
    );
};

export default Support;




















{/* <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
<p>


    Support:-when you click the “phone??” icon, a menu will pop up that offers: consults (birth support, 1:1 consults, carseat consult, Birth plan audit), contact us (submit requests, email us)

</p>
</div> */}





// Email us today!

// tranquilitybyhehe@gmail.com

// ​
// Give us a call!

// Boston, Massachusetts, United States

// 601.516.0829

// ​
// Connect with us on social media!

// Stay up-to-date with what we are doing and the latest topics in pregnancy, birth, and parenthood.










// <input type="checkbox"></input>















