

import React from "react";
// import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'features/user';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import logoImg from '../assets/birth_lounge_logo.png'
// MATERIAL UI IMPORTS
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Hidden from '@mui/material/Hidden'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
// import Menu from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// STYLE FOR MENUBAR BACKGROUND
const StyledPaper = (props) => (
	<Paper
		sx={{
			background: "#E7F4F0", 
			borderRadius: '0px', 
			//opacity: '85%'
		}}
	>
		{props.children}
	</Paper>
);

// LOGO PROPERTIES
const StyledLogoPaper = (props) => (
	<Paper
		sx={{
			padding: '0.5rem',
			margin: '0rem',
			backgroundColor: '#E7F4F0', //'#034488',
			backgroundImage: `url(${logoImg})`,
			repeat: 'no-repeat',
			backgroundSize: 'cover',
			width: "6rem", // these were 15 and 6
			height: "6rem",
			border: '0px',
			boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
			//opacity: '85%',
			//borderRadius: '5px'
		}}
	>
		{props.children}
	</Paper>
);

// STYLE FOR HAMBURGER MENU ICON
// const StyledHamburgerMenu = (props) => (
// 	<Menu
// 		sx={{
// 			mx: 2, width: '4rem', height: '4rem', color: "#034488",
// 		}}
// 	>
// 		{props.children}
// 	</Menu>
// );

const StyledAccountCircleIcon = (props) => (
	<AccountCircleIcon
		sx={{
			mx: 2, width: '3rem', height: '3rem', 
			color: "#73AFA4",
		}}
	>
		{props.children}
	</AccountCircleIcon>
);





// STYLE FOR DRAWER BACKGROUND
const StyledBox = (props) => (
	<Box
		sx={{
			// background: '#edece8', 
			height: '100%'
		}}
	>
		{props.children}
	</Box>
);

// STYLE FOR CLOSE DRAWER X (CLOSE) ICON
const StyledCloseIcon = () => (
	<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
		<CloseIcon
			sx={{
				mx: 2, width: '4rem', height: '4rem', 
				 color: '#73AFA4',
			}}
		>
		</CloseIcon>
	</div>
);

// DRAWER PROPERTIES
const StyledDrawer = styled(Drawer)(({ theme }) => ({
	'& .MuiPaper-root': {
		opacity: 0.85, // Adjust the opacity of the drawer
		backgroundColor: '#E7F4F0',
	},
}));


// sx={{ backgroundColor: '#0E9789', }}



// STYLE FOR DRAWER BACKGROUND
const StyledListItem = (props) => (
	<ListItem
		sx={{
			//backgroundColor: '#ccdde8', 
			marginBottom: '0.5rem'
		}}
	>
		{props.children}
	</ListItem>
);


const Navbar = () => {
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector(state => state.user);



	const menuCategories = [
		{ label: 'Landing', url: '' },
		{ label: 'Login', url: 'login' },
		{ label: 'Home', url: 'home' },
		{ label: 'Register', url: 'register' },
		
	]
	const [mobileOpen, setMobileOpen] = React.useState(false);
	function handleDrawerToggle() {
		setMobileOpen(!mobileOpen)
	}
	let navigate = useNavigate();

	function handleClick(textArg) {
		console.log('navigate ', textArg.url)
		if (textArg.url == 'logout') {
			dispatch(logout())
			navigate('/')
		}
		else {
			navigate('/' + textArg.url)
		}

	}

	// const authLinks = (
	// 	<>
	// 		<li className='nav-item'>
	// 			<NavLink className='nav-link' to='/dashboard'>
	// 				Dashboard
	// 			</NavLink>
	// 		</li>
	// 		<li className='nav-item'>
	// 			<a className='nav-link' href='#!' onClick={() => dispatch(logout())}>
	// 				Logout
	// 			</a>
	// 		</li>
	// 	</>
	// );

	// const guestLinks = (
	// 	<>
	// 		<li className='nav-item'>
	// 			<NavLink className='nav-link' to='/login'>
	// 				Login
	// 			</NavLink>
	// 		</li>
	// 		<li className='nav-item'>
	// 			<NavLink className='nav-link' to='/register'>
	// 				Register
	// 			</NavLink>
	// 		</li>
	// 	</>
	// );













	return (
		<div>
			<AppBar position="relative" elevation={3} 
			//sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
			>
				<StyledPaper>
					<Box
						m={0}
						display="flex"
						justifyContent="space-between"

					>

						<RouterLink to={{ pathname: "/home" }} component="button" >
							<StyledLogoPaper>
							</StyledLogoPaper>
						</RouterLink>

						<IconButton
						sx={{disableFocusRipple: 'true'}}
							onClick={handleDrawerToggle}
						>

							{/* <StyledHamburgerMenu>
							</StyledHamburgerMenu> */}
							<StyledAccountCircleIcon></StyledAccountCircleIcon>

						</IconButton>
					</Box>
				</StyledPaper>
			</AppBar>




			<nav>
				<Hidden>
					<StyledDrawer

						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<StyledBox id="STYLED-BOX-ELEMENT" sx={{   width: '100%', display: 'flex', justifyContent: 'center' }}>
							<IconButton
								sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
								onClick={handleDrawerToggle} >

								<StyledCloseIcon>
								</StyledCloseIcon>

							</IconButton>




							<div>
								<List  

								

								>
									{menuCategories.map((text) => (
										<StyledListItem key={text.label}>

											<Button onClick={() => handleClick(text)}
												sx={{ fontSize: '1.5rem', color: '#0E9789', }}
											> {text.label}</Button>

										</StyledListItem>
									))}
								</List>
							</div>








						</StyledBox>
					</StyledDrawer>
				</Hidden>
			</nav>




		</div>

	);
};

export default Navbar;





// <nav className='navbar navbar-expand-lg bg-light'>
// 	<div className='container-fluid'>
// 		<Link className='navbar-brand' to='/'>
// 			Auth Site
// 		</Link>
// 		<button
// 			className='navbar-toggler'
// 			type='button'
// 			data-bs-toggle='collapse'
// 			data-bs-target='#navbarNav'
// 			aria-controls='navbarNav'
// 			aria-expanded='false'
// 			aria-label='Toggle navigation'
// 		>
// 			<span className='navbar-toggler-icon'></span>
// 		</button>
// 		<div className='collapse navbar-collapse' id='navbarNav'>
// 			<ul className='navbar-nav'>
// 				<li className='nav-item'>
// 					<NavLink className='nav-link' to='/'>
// 						Home
// 					</NavLink>
// 				</li>
// 				{isAuthenticated ? authLinks : guestLinks}
// 			</ul>
// 		</div>
// 	</div>
// </nav>

















































// import { Link, NavLink } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { logout } from 'features/user';

// const Navbar = () => {
// 	const dispatch = useDispatch();
// 	const { isAuthenticated } = useSelector(state => state.user);

// 	const authLinks = (
// 		<>
// 			<li className='nav-item'>
// 				<NavLink className='nav-link' to='/dashboard'>
// 					Dashboard
// 				</NavLink>
// 			</li>
// 			<li className='nav-item'>
// 				<a className='nav-link' href='#!' onClick={() => dispatch(logout())}>
// 					Logout
// 				</a>
// 			</li>
// 		</>
// 	);

// 	const guestLinks = (
// 		<>
// 			<li className='nav-item'>
// 				<NavLink className='nav-link' to='/login'>
// 					Login
// 				</NavLink>
// 			</li>
// 			<li className='nav-item'>
// 				<NavLink className='nav-link' to='/register'>
// 					Register
// 				</NavLink>
// 			</li>
// 		</>
// 	);

// 	return (
// 		<nav className='navbar navbar-expand-lg bg-light'>
// 			<div className='container-fluid'>
// 				<Link className='navbar-brand' to='/'>
// 					Auth Site
// 				</Link>
// 				<button
// 					className='navbar-toggler'
// 					type='button'
// 					data-bs-toggle='collapse'
// 					data-bs-target='#navbarNav'
// 					aria-controls='navbarNav'
// 					aria-expanded='false'
// 					aria-label='Toggle navigation'
// 				>
// 					<span className='navbar-toggler-icon'></span>
// 				</button>
// 				<div className='collapse navbar-collapse' id='navbarNav'>
// 					<ul className='navbar-nav'>
// 						<li className='nav-item'>
// 							<NavLink className='nav-link' to='/'>
// 								Home
// 							</NavLink>
// 						</li>
// 						{isAuthenticated ? authLinks : guestLinks}
// 					</ul>
// 				</div>
// 			</div>
// 		</nav>
// 	);
// };

// export default Navbar;
