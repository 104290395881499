import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';

import homeImg from './home.png'


import { useNavigate } from 'react-router-dom';
import courseLogo from './open-book.png';
import colorsImg from './colors.png';

import pdfFile from "./download/design_doc.pdf"


import download_icon from './download_icon.png'


import '../../fonts/font.css'

import settings_icon from './svg/settings_icon.svg'
import shop_icon from './svg/shop_icon.svg'
import new_icon from './svg/new.png'
import favorite_icon from './svg/favorite.png'
import course_icon from './svg/course.png'
import progress_icon from './svg/progress.svg'
import blog_icon from './svg/blog.png'

const Home = () => {

    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        // navigate('/library'); // Replace with your desired route
        navigate(pathURL); // Replace with your desired route
    };



    const pdfUrl = "./download/design_doc.pdf"; // Or a public URL




    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Dashboard page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div
                style={{
                   // fontFamily: 'playfair',
                    
                    display: 'flex',
                    justifyContent: 'center',
                    height: '110vh',

                }}



                
                >





                    {/* <h3
                        style={{
                            ///paddingTop: '3rem',
                            fontFamily: 'playfair',
                            //fontWeight: 'italic',
                            // fontSize: '3rem',
                            // textAlign: 'center',
                            // marginBottom: '1rem',
                        }}
                    >Welcome Back</h3>
                    <h3> {user.first_name} {user.last_name}</h3>

                    <div
                        style={{

                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                        }}
                    >

                    </div> */}












                    {/* <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>

                            Home -when you click the “House” icon, a menu will pop up that offers: (profile, settings, other courses, search (auto populate search bar pops up below as they type), babys weekly progress

                        </p>
                    </div>






                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={homeImg}></img>
                    </div> */}






















                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateRows: 'auto auto 1fr',
                        margin: '0',
                        
                        //height: '100vh',
                        maxWidth: '500px',

                        // marginBottom: '10rem',
                        
                    }}>
                        {/* Header */}
                        <div style={{
                            gridColumn: '1 / span 2',
                            padding: '20px',
                            // backgroundColor: '#f0f0f0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <div>

                                <h3
                                    style={{
                                        fontFamily: 'playfair',
                                    }}
                                >Welcome Back</h3>
                                <h3> {user.first_name} {user.last_name}</h3>


                            </div>


                            <div

                            >
                                <img src={settings_icon} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                            </div>
                        </div>



                        {/* Search */}
                        <div style={{
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            gridColumn: '1 / span 2',
                            fontSize: '1.5rem',
                        }}>
                            <input style={{
                                width: '100%',
                                backgroundColor: '#BB98D9',
                                padding: '10px',
                                margin: '0px',
                                border: 'none',
                                borderRadius: '0.5rem',
                                color: 'black',
                                outline: 'none', // Remove the outline on focus

                                '::placeholder': {
                                    color: 'fff'
                                }

                            }}
                                type="text" placeholder="Search..." />
                        </div>









                        {/* Content */}
                        <div style={{
                            gridColumn: '1 / span 2',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 11rem)',
                            gridTemplateRows: 'repeat(3, 11rem)',
                            gap: '20px'
                        }}>








                            <div

                                onClick={() => handleButtonClick('/stripe')}

                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={new_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>New Content!</h3>
                            </div>

                            <div
                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={favorite_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>Favorites</h3>
                            </div>

                            <div
                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={shop_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>Shop</h3>
                            </div>




                            <div

                                onClick={() => handleButtonClick('/coursemodule')}

                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={course_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>Courses</h3>
                            </div>




                            <div
                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={progress_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>Progress Tracker</h3>
                            </div>




                            <div
                                style={{
                                    borderRadius: '8px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#BBE7E0',
                                    height:'11rem',
                                    width:'11rem',
                                }}
                            >
                                <img src={blog_icon} alt={`Icon`} style={{ width: '60%', height: '60%', marginBottom: '10px' }} />
                                <h3>Blog</h3>
                            </div>































                        </div>
                    </div>













                    {/* <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>Color Palette with suggested additional colors</p>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={colorsImg}
                            style={{
                                width: '20rem',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '5rem',
                            }}
                        ></img>
                    </div> */}











                    {/* <div style={{
                        paddingBottom: '5rem',
                    }}>
                    </div> */}



                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

export default Home;































{/* <p>User Details</p>
<ul>
    <li>First Name: {user.first_name}</li>
    <li>Last Name: {user.last_name}</li>
    <li>Email: {user.email}</li>
</ul> */}












{/* 

                        <div onClick={() => handleButtonClick('/course')}
                            style={{
                                marginTop: '3rem',
                                marginBottom: '3rem',
                                padding: '1rem',
                                backgroundColor: '#97CBC2',
                                width: '9rem',
                                height: '9rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                borderRadius: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    style={{
                                        backgroundColor: '#97CBC2',
                                        border: 'none',
                                    }}
                                >
                                    <img style={{
                                        height: '6rem',
                                        width: '6rem',
                                        align: 'center',
                                        backgroundColor: '#97CBC2',
                                    }}
                                        src={courseLogo} />
                                </button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <p style={{
                                    fontWeight: 'bold', textAlign: 'center',
                                }}>Course Page</p>
                            </div>
                        </div>

 */}

























{/* <div onClick={() => handleButtonClick('/modal')}
                            style={{
                                marginTop: '3rem',
                                marginBottom: '3rem',
                                padding: '1rem',
                                backgroundColor: '#97CBC2',
                                width: '9rem',
                                height: '9rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                borderRadius: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    style={{
                                        backgroundColor: '#97CBC2',
                                        border: 'none',
                                    }}
                                > */}




{/* <img style={{
                                        height: '6rem',
                                        width: '6rem',
                                        align: 'center',
                                        backgroundColor: '#97CBC2',
                                    }}
                                        src={courseLogo} /> */}





{/* </button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <p style={{
                                    fontWeight: 'bold', textAlign: 'center',
                                }}>Popup Modal Example</p>
                            </div>
                        </div> */}













{/* 
                    <div

                        style={{

                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div
                            style={{

                                width: '6rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                            }}
                        >

                            <a href={pdfFile} download
                                style={{
                                    fontSize: '1.8rem',
                                    backgroundColor: '#97CBC2',
                                    padding: '1rem',
                                    borderRadius: '1rem',
                                    color: 'black',
                                }}>
                                <img src={download_icon}></img>

                            </a>

                        </div>

                    </div>
                    <p style={{ textAlign: 'center' }}>Download the Design Document PDF</p> */}







{/* <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>

                            Color Palette with suggested additional colors
                        </p>
                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={colorsImg}

                            style={{
                                width: '20rem',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '5rem',
                            }}

                        ></img>
                    </div> */}























/*




        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img src={`icon.png`} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <span>Label</span>
        </div>


        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img src={`icon.png`} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <span>Label</span>
        </div>


        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img src={`icon.png`} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <span>Label</span>
        </div>


        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img src={`icon.png`} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <span>Label</span>
        </div>


        <div
            style={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img src={`icon.png`} alt={`Icon`} style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
            <span>Label</span>
        </div>




        */





















/*

















                    <div onClick={() => handleButtonClick('/coursemodule')}
                style={{
                    marginTop: '13rem',
                    marginBottom: '3rem',
                    padding: '1rem',
                    backgroundColor: '#97CBC2',
                    width: '9rem',
                    height: '9rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: '1rem',
                    
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <button
                        style={{
                            backgroundColor: '#97CBC2',
                            border: 'none',
                        }}
                    >
                        <img style={{
                            height: '5rem',
                            width: '5rem',
                            backgroundColor: '#97CBC2',
                        }}
                            src={courseLogo} />
                    </button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <p style={{
                        fontWeight: 'bold', textAlign: 'center',
                    }}>Course Module</p>
                </div>
            </div>
















*/