

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

// import SingleModule from './SingleModule'

// REDUX OPERATION MODULES
import * as selectLesson from "../../redux/pagemodules/courses/selectLesson.js"
import { useNavigate } from 'react-router-dom';



const CourseLessonRender = (props) => {

    // Extract data from props
    const { data } = props;
    console.log('CourseLessonRender props', props)
    let renderDisplayItems = data.lessons //['1','2','3']//data.selectedCourse
    console.log('renderDisplayItems', renderDisplayItems)
    if (renderDisplayItems === undefined) { renderDisplayItems = [] }


    const navigate = useNavigate();

    const selectLessonQuery = async (lessonData) => {

        //console.log('selectLesson', lessonData)
        console.log('selectLesson')
        console.log('selectLesson', props)
        console.log('selectLesson')
        await selectLesson.selectLesson(lessonData)
        //navigate(pathURL);
        await navigate('/lesson');
    };




    return (
        <div style={{ width: '100%', margin: '0rem', padding: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: "wrap",
                //borderWidth: '2px',
                //borderColor: '#edece8',
                //borderStyle: 'solid',
                //paddingBottom: '1rem',
                margin: '0rem',
                padding: '0rem',
            }}>
                <div style={{
                    width: '100%', height: 'auto'
                }}
                >



                    <div

                    // style={{
                    //     backgroundColor: '#9B59B6', // A467CC BB98D9
                    //     width: '100%', height: '5px',
                    //     borderRadius: '2px',
                    //     opacity: '70%',
                    // }}

                    >

                    </div>

                    {renderDisplayItems.map((item, index) => (

                        <div

                            key={`displayItem_${index}`}

                        >

                            {/* <p>ITEM, {index} </p> */}
                            <div onClick={() => selectLessonQuery(item)}

                                style={{
                                    backgroundColor: '#97CBC2',
                                    padding: '0.5rem',
                                    margin: '1rem',

                                    cursor: 'pointer',
                                    borderRadius: '0.5rem',
                                    textAlign: 'left',

                                }}

                            >

                                <p
                                    style={{
                                        padding: '0rem',
                                        margin: '0rem',
                                        fontSize: '1.3rem',
                                        fontWeight:'bold',
                                    }}
                                ><span style={{margin:'0px', padding:'3px', borderRadius:'5px', backgroundColor:'#73AFA4'}}>Lesson {index + 1}</span>  {item.lesson_title} </p>


                                <p
                                    style={{
                                        padding: '0rem',
                                        marginTop: '0.5rem',
                                        marginBottom: '0rem',
                                        fontSize: '1rem',
                                    }}
                                >{item.content_summary} </p>


                            </div>

                            {/* <SingleModule data={item} ></SingleModule> */}

                        </div>





                    ))}





                </div>


            </div>
        </div >
    );
};

export default CourseLessonRender;
