import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';



import courseModuleImage_1 from './course_module_1.png';
import courseModuleImage_2 from './course_module_2.png';
import lessonImage_1 from './lesson_1.png';

import exampleImage_1 from './example_1.png';
import exampleImage_2 from './example_2.jpg';


import { useNavigate } from 'react-router-dom';
import courseLogo from './open-book.png';



const CourseModule = () => {



    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL); // Replace with your desired route
    };





    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Template page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>



                    <div
                        onClick={() => handleButtonClick("/home")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "9rem",
                            height: "4rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom: '2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            Back
                        </p>
                    </div>



                    <h3 style={{ textAlign: 'center', }}>Course Module</h3>








                    {/* <div onClick={() => handleButtonClick('/lesson')}
                        style={{
                            marginTop: '3rem',
                            marginBottom: '3rem',
                            padding: '1rem',
                            backgroundColor: '#97CBC2',
                            width: '9rem',
                            height: '9rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: '#97CBC2',
                                    border: 'none',
                                }}
                            >
                                <img style={{
                                    height: '5rem',
                                    width: '5rem',
                                    backgroundColor: '#97CBC2',
                                }}
                                    src={courseLogo} />
                            </button>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p style={{
                                fontWeight: 'bold', textAlign: 'center',
                            }}>Lesson</p>
                        </div>
                    </div> */}











                    <div>
                        <p
                            style={{
                                fontSize: '1.3rem',
                            }}
                        >
                            This can be a separate page or a dropdown menu on the 'course' page. It has been rendered separately here for clarity. It will render a summary of all lessons in each module in addition to some metadata which will include: name of lesson, estimated completion time, checkbox indicating whether lesson is completed, brief lesson summary, and icons indicating contents of the lesson such as notes, video, and downloads. To reduce latency this data will all be loaded from a single server request on page load of course page.
                        </p>
                    </div>












                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={courseModuleImage_1}></img>
                    </div>



                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={courseModuleImage_2}></img>
                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={exampleImage_1}></img>

                    </div>





                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={exampleImage_2}></img>

                    </div>













                    <div style={{
                        paddingBottom: '5rem',
                    }}></div>

                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

export default CourseModule;
