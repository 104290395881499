import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';



import courseImage_1 from './course_1.png';
import courseImage_2 from './course_2.png';
import { useNavigate } from 'react-router-dom';
import courseLogo from './open-book.png';

import exampleImage_3 from './example_3.jpg';

import TestAPIQuery from './TestAPIQuery'




// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
// import * as initializeOperations from "../../redux/pagemodules/library/initializeLibrary.js"
// REDUX SELECTORS
import { COURSE_DATA } from '../../redux/selectors';



import CourseModuleRender from './CourseModuleRender'

import '../../fonts/font.css'


const Course = (props) => {



    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL); // Replace with your desired route
    };


    console.log('Course props',props)


    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Template page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>


                    <div
                        onClick={() => handleButtonClick("/library")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "9rem",
                            height: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom:'2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            Back
                        </p>
                    </div>



                    <h3 style={{ textAlign: 'center', 
                        fontSize:'1.8rem', 
                        fontWeight:'bold', 
                        fontFamily: 'imported-font-varela',}}>{props.COURSE_DATA_FROM_SELECTOR.courseObject.courseTitle}</h3>









                    <CourseModuleRender data={props.COURSE_DATA_FROM_SELECTOR} ></CourseModuleRender>




















                    {/* <div onClick={() => handleButtonClick('/coursemodule')}
                        style={{
                            marginTop: '13rem',
                            marginBottom: '3rem',
                            padding: '1rem',
                            backgroundColor: '#97CBC2',
                            width: '9rem',
                            height: '9rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: '1rem',
                            
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: '#97CBC2',
                                    border: 'none',
                                }}
                            >
                                <img style={{
                                    height: '5rem',
                                    width: '5rem',
                                    backgroundColor: '#97CBC2',
                                }}
                                    src={courseLogo} />
                            </button>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p style={{
                                fontWeight: 'bold', textAlign: 'center',
                            }}>Course Module</p>
                        </div>
                    </div> */}













                    {/* <div onClick={() => handleButtonClick('/coursemodule')}
                        style={{
                            marginTop: '3rem',
                            marginBottom: '3rem',
                            marginLeft: '1rem',
                            backgroundColor: '#97CBC2',
                            width: '9rem',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '1rem',
                        }}
                    >
                        <div>
                            <button
                                style={{
                                    height: '5rem',
                                    width: '7rem',
                                    backgroundColor: '#97CBC2',
                                    border: 'none',
                                }}
                            >
                                <img style={{
                                    height: '5rem',
                                    width: '5rem',
                                    backgroundColor: '#97CBC2',
                                }}
                                    src={courseLogo} />
                            </button>
                            <p style={{
                                fontWeight: 'bold',
                            }}>Course Module</p>
                        </div>
                    </div> */}







                    {/* <div>
                        <p
                            style={{
                                fontSize: '1.3rem',
                            }}
                        >
                            This page, after loading, will query the server for data for an entire course and populate all sections as well as the user data for that course to determine completion level and render completion bars/rings. Data for individual lessons will not be loaded. The preview versions of the course will also be populated however these will be rendered with a lock indicating they are unavilable in preview mode. Server will use auth token to determine user access permissions for course and response will be rendered based on access level, so no protected data will be sent to unauthorized users.
                        </p>
                    </div> */}






                    {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={courseImage_1}></img>
                    </div> */}



                    {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={courseImage_2}></img>
                    </div> */}








                    {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={exampleImage_3}></img>

                    </div> */}












                    <div style={{
                        paddingBottom: '5rem',
                    }}></div>



                    {/* <TestAPIQuery></TestAPIQuery> */}


                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

//export default Course;

const mapStateToProps = (state) => ({
    COURSE_DATA_FROM_SELECTOR: COURSE_DATA(state),
});

export default connect(mapStateToProps)(Course);


