
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";


import { Avatar } from '@mui/material';

import logo_icon from "./logo.svg"
import { useNavigate } from 'react-router-dom';



import logo_1 from "./logo_1.png"
import logo_2 from "./birth_lounge_logo_3.png"
import logo_3 from "./logo_3.png"
import logo_4 from "./logo_4.png"





import parse from 'html-react-parser'


// REDUX OPERATION MODULES
import * as selectCourse from "../../redux/pagemodules/library/selectCourse.js"

import '../../fonts/font.css'













{/* <div style={{
    textAlign: 'center',
    paddingBottom: '0rem',
    fontFamily: 'imported-font-varela',

}}>
    <h3
        style={{
            fontSize: '1.7rem',
            fontWeight:'bold',
        }}
    >LIBRARY</h3>
</div> */}













const MENU_TEXT_COLOR = 'black'



const StyledAddIcon = () => (
    <div style={{
        width: '100%', display: 'flex', justifyContent: 'center',
        padding: '0rem', margin: '0rem',
    }}>
        <Add
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: MENU_TEXT_COLOR,
                padding: '0rem', margin: '0rem',
            }}
        >
        </Add>
    </div>
);

const StyledRemoveIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Remove
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: MENU_TEXT_COLOR,
                padding: '0rem', margin: '0rem',
            }}
        >
        </Remove>
    </div>
);




const CourseDetails = (props) => {









    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL);
    };







    const [openStatus, setOpenStatus] = useState(false);
    const { courseTitle } = props
    const { data_description } = props
    const { subscription_plans } = props



    const { logoIndex } = props
    const logoArray = [
        logo_1,
        logo_2,
        logo_3,
        logo_4,
    ]
    const selectedLogo = logoArray[logoIndex]

    const { isLocked } = props


    let MENU_BACKGROUND_COLOR = '#97CBC2' // '#D1EEE8' 
    let MENU_AVATAR_COLOR = '#73afa4'
    let MENU_BUTTON_COLOR = "#73afa4"

    if (isLocked == true) {
        MENU_BACKGROUND_COLOR = '#bfbfbf' // '#ECF0F1' #1e2729  808080   A6A6A6    bfbfbf   cccccc   1c4e48
        MENU_AVATAR_COLOR = '#cccccc'
        MENU_BUTTON_COLOR = "#cccccc" // 4a5054
    }




    // let MENU_BACKGROUND_COLOR = '#BBE7E0' // '#D1EEE8' 
    // let MENU_AVATAR_COLOR = '#97CBC2'
    // let MENU_BUTTON_COLOR = "#97CBC2"

    // if (isLocked == true) {
    //     MENU_BACKGROUND_COLOR = '#1c4e48' // '#ECF0F1' #1e2729  808080   A6A6A6    bfbfbf   cccccc
    //     MENU_AVATAR_COLOR = '#4a5054'
    //     MENU_BUTTON_COLOR = "#A6A6A6"
    // }










    const { course_id } = props
    const handleSelectCourse = (pathURL) => {
        console.log('handleSelectCourse')
        selectCourse.selectCourse(course_id)
        //navigate(pathURL);
    };




    const selectCourseQuery = async (pathURL) => {

        console.log('selectCourseQuery', course_id)
        let courseObject = {
            courseTitle: courseTitle,
            course_id: course_id,
            subscription_plans: subscription_plans,
        }
        await selectCourse.selectCourse(courseObject)
        //navigate(pathURL);
        await navigate('/course');
    };



    const handlePurchaseCourse = async (pathURL) => {

        console.log('handlePurchaseCourse', course_id)
        let courseObject = {
            courseTitle: courseTitle,
            course_id: course_id,
            subscription_plans: subscription_plans,
        }
        await selectCourse.selectCourse(courseObject)
        await navigate('/purchase');

    };








    return (

        <div
            style={{
                margin: '0rem',
            }}
        >
            <style>
                {`
                    .fade {
                        opacity: 1;
                        transition: opacity 0.3s ease;
                    }
                    .visible {
                        opacity: 1;
                    }
                    .hidden {
                        opacity: 0;
                    }
                `}
            </style>

            <Card sx={{
                minWidth: 300,
                backgroundColor: MENU_BACKGROUND_COLOR,
                color: MENU_TEXT_COLOR,
                borderRadius: '0px',
                opacity: '100%',
                margin: '0rem',
                marginTop: '0.5rem',
                padding: '0rem',
                borderColor: 'black',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                boxShadow: '2px 2px 5px rgba(03, 44, 88 0.5)'
            }}>







                <CardHeader
                    onClick={() => setOpenStatus(!openStatus)}









                    avatar={

                        <Avatar

                            sx={{
                                width: '6rem',
                                height: '4rem',
                                borderRadius: '1rem',
                                backgroundColor: MENU_AVATAR_COLOR //'#97CBC2' //</Card>'#D1EEE8', // '#BBE7E0'  
                                // light green -- D1EEE8      
                                //  808080   A6A6A6    bfbfbf    cccccc
                            }}>


                            {isLocked && (

                                <img style={{
                                    width: '1.5rem',
                                    height: '1.5rem',
                                }} src={logo_icon} alt="Logo" />

                            )}

                            <img
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                }}
                                src={selectedLogo}
                                alt="Logo"
                            />




                        </Avatar>

                    }























                    aria-label="expand"
                    size="medium"

                    titleTypographyProps={{
                        fontFamily: 'imported-font-varela',
                        fontSize: "1.3rem", fontWeight: 'bold', textAlign: 'left',
                    }}
                    title={courseTitle}
                    action={
                        <IconButton
                            onClick={() => setOpenStatus(!openStatus)}
                            aria-label="expand"
                            size="large"
                            sx={{
                                width: '4rem',
                                height: '4rem',
                            }}
                        >
                            {openStatus ? <StyledRemoveIcon />
                                : <StyledAddIcon />}

                        </IconButton>
                    }
                >
                </CardHeader>

                <div style={{
                    padding: '0rem', margin: '0rem',
                }}>

                    <Collapse in={openStatus} timeout="auto"
                        unmountOnExit

                        style={{
                            padding: '0rem', margin: '0rem', border: '0rem'
                        }}

                    >
                        <CardContent
                            style={{
                                padding: '0rem', margin: '0rem', border: '0rem',
                            }}
                        >
                            <Container

                                sx={{
                                    height: 'auto',
                                    // lineHeight: 0,
                                    width: '100%',
                                    padding: '0rem',
                                    margin: '0rem',
                                }}>

                                <div
                                    style={{
                                        padding: '1rem',
                                        paddingTop: '0rem',
                                    }}
                                >



                                    {/* <div
                                    // sx={{
                                    //     fontSize: '1.2rem', marginBottom: '1rem'
                                    // }}
                                    // align={'justify'}
                                    >

                                        {parse(data_description)}
                                    </div> */}



                                    <div
                                    >{parse(data_description)}</div>














                                    <div



                                    >













                                        {isLocked && (
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                            }}
                                            >
                                                <div
                                                    onClick={() => selectCourseQuery("/course")}
                                                    style={{
                                                        backgroundColor: MENU_BUTTON_COLOR, //"#97CBC2",
                                                        width: "9rem",
                                                        height: "3rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: "1rem",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            margin: "0", // Remove padding for better vertical centering
                                                            padding: "0",
                                                        }}
                                                    >
                                                        Course Preview
                                                    </p>
                                                </div>

                                                <div
                                                    onClick={() => handlePurchaseCourse("/purchase")}
                                                    style={{
                                                        backgroundColor: MENU_BUTTON_COLOR, //"#97CBC2",
                                                        width: "9rem",
                                                        height: "3rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: "1rem",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            margin: "0", // Remove padding for better vertical centering
                                                        }}
                                                    >
                                                        Purchase
                                                    </p>
                                                </div>
                                            </div>
                                        )}












                                        {!isLocked && (
                                            <div

                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                }}

                                            >
                                                <div
                                                    onClick={() => selectCourseQuery("/course")}
                                                    style={{
                                                        backgroundColor: MENU_BUTTON_COLOR, //"#97CBC2",
                                                        width: "9rem",
                                                        height: "3rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: "1rem",
                                                        cursor: "pointer",
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            margin: "0", // Remove padding for better vertical centering
                                                            padding: "0",
                                                        }}
                                                    >
                                                        View Course
                                                    </p>
                                                </div>
                                            </div>
                                        )}







                                    </div>



                                </div>
                            </Container>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
        </div >
    );
};

export default CourseDetails;
























// avatar={
//     <Avatar
//         sx={{
//             width: '6rem',
//             height: '4rem',
//             borderRadius: '1rem',
//             backgroundColor: '#A6A6A6', // '#BBE7E0'  D1EEE8      
//             //  808080   A6A6A6    bfbfbf    cccccc
//         }}>

//         {/* <img style={{
//             width: '1.5rem',
//             height: '1.5rem',
//         }} src={logo_icon} alt="Logo" />

//         <img style={{
//             width: '3rem',
//             height: '3rem',
//         }} src={selectedLogo} alt="Logo" /> */}


//         {/* Conditionally render the first image */}
//         {isLocked && (

//             <img style={{
//                 width: '1.5rem',
//                 height: '1.5rem',
//             }} src={logo_icon} alt="Logo" />



//             // <img
//             //     style={{ width: "1.5rem", height: "1.5rem", padding: "1rem" }}
//             //     src={logo_icon}
//             //     alt="Logo"
//             // />



//         )}

//         {/* Always render the second image */}
//         <img
//             style={{
//                 width: "3rem",
//                 height: "3rem",
//             }}
//             src={selectedLogo}
//             alt="Logo"
//         />




//     </Avatar>

// }







