// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 



// REDUX
import { store } from '../../store.js';


// REDUX ACTIONS
import a05_FetchCommunityTopicSuccess from "../../actions/a05_FetchCommunityTopicSuccess"


//import a04_FetchUserAccessSuccess from "../../actions/a04_FetchUserAccessSuccess"
// let fetchEndpointURL = '/api/getcourses'

const devModeBool = false

export const fetchDataAfterPageLoad = async (fetchEndpointURL) => {

    if (devModeBool === true) {
        console.log('Component mounted, if this happens twice check strict mode');
    }


    try {
        const response = await fetch(fetchEndpointURL);
        const data = await response.json();
        console.log('data ', data)

        await store.dispatch(a05_FetchCommunityTopicSuccess(data));


    } catch (error) {
        console.error('Error:', error);
    }




}


















    // //console.log('fetchDataAfterPageLoad')
    // try {

    //     const response = await fetch(fetchEndpointURL.course_url);
    //     const data = await response.json();
    //     if (devModeBool === true) {
    //          console.log('FETCH_DATA course_url SUCCESS ----- ', data)
    //     }

    //     //await store.dispatch(a01_FetchLibraryDataSuccess(data));

    //     //////console.log('FETCH_DATA user_access_url SUCCESS ----- ')



    //     const response2 = await fetch(fetchEndpointURL.user_access_url, {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             query: 'searchTerm',
    //             // Add any other search filters here if needed
    //           }),
    //     });



    // //     const data2 = await response2.json();
    // //     if (devModeBool === true) {
    // //         console.log('FETCH_DATA user_access_url SUCCESS ----- ', data2)
    // //    }

    //    const payloadBatchObject = {
    //     course: data.courses,
    //     //access: data2,
    //     subscription_plans: data.subscription_plans,
    //    }
    //    console.log('store ', store)
    //    await store.dispatch(a04_FetchUserAccessSuccess(payloadBatchObject));

    //     /////await store.dispatch(a02_CreateDataSearchObjects(data));
    //     // setLoading(false);

    // } catch (error) {
    //     if (devModeBool === true) {
    //         console.error('Error:', error);
    //         console.log('FETCH_DATA ----- ERROR')
    //     }
    // }

