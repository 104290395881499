import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';
import Details from './Details';

import { useNavigate } from 'react-router-dom';


import libraryImage_1 from './library_1.png';
import libraryImage_2 from './library_2.png';
import libraryImage_3 from './library_3.png';




import { useState, useEffect, useRef } from "react";







// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
import * as initializeOperations from "../../redux/pagemodules/library/initializeLibrary.js"
import { LIBRARY_DATA } from '../../redux/selectors';






import LibraryCourseList from './LibraryCourseList'




import '../../fonts/font.css'





const Library = (props) => {


    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL);
    };



    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    // console.log('authenticated: ', isAuthenticated)
    // console.log('loading: ', loading)
    // console.log('user: ', user)




    const [pageloading, setPageLoading] = useState(true);
    let fetchEndpointURL = {
        course_url: '/api/getcourses',
        user_access_url: '/api/getuseraccessdata'
    }

    useEffect(() => {
        const initializeFetch = async () => {
            if (pageloading === true) {
                await initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)
                setPageLoading(false);
            }
        };
        initializeFetch();
    }, []);



























    const data_description = [
        'Evidence-based articles delivered to your phone to help you navigate conversations in prenatals and design your desired birth!',
        'If youre ready to go from being a birth-passenger to being a birth-powerhouse, but you need someone whos been-there, done-that to guide the way... I created The Birth Lounge for you!',
        'Help support your partner understand their role in labor, learn comfort measures in birth & know how to properly advocate for you and your birth goals!',
        'Learn how to evaluate when  to push & engage in spontaneous pushing yielding shorter push times and better neonatal outcomes than provider guided pushing.',
    ]














    // if (!isAuthenticated && !loading && user === null){
    //     console.log('authenticated: ', isAuthenticated)
    //     console.log('loading: ', loading)
    //     console.log('user: ', user)

    //     const timer = setTimeout(() => {
    //         return <Navigate to='/login' />;
    //         //navigate('/login'); // Replace '/login' with your desired route
    //       }, 3000); // Redirect after 3 seconds (3000 milliseconds)

    // }










    if (pageloading) {
        return <div></div>;
    } else if (!isAuthenticated && !loading && user === null) {
        return <Navigate to="/login" />;
    } else {
        return (
            <Layout title='The Birth Lounge' content='Library page'>
                {loading || user === null ? (
                    <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </div>
                ) : (
                    <div                                
                     style={{
                        minHeight:'120vh'
                    }}>


                        {/* <div
                            onClick={() => handleButtonClick("/home")}
                            style={{
                                backgroundColor: "#97CBC2",
                                width: "9rem",
                                height: "3rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "1rem",
                                cursor: "pointer",
                                marginBottom: '2rem',
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    margin: "0", // Remove padding for better vertical centering
                                }}
                            >
                                Back
                            </p>
                        </div> */}


                        <div style={{
                            //textAlign: 'center',
                            paddingBottom: '1rem',
                            //fontFamily: 'imported-font-varela',

                        }}>
                            <h3
                                style={{
                                    fontSize: '2rem',
                                    fontWeight: 'bold',
                                }}
                            >Library</h3>
                        </div>



                        <LibraryCourseList data={props.LIBRARY_DATA_FROM_SELECTOR} ></LibraryCourseList>





                        {/* <Details
                        courseTitle={'The Birth Lounge Essential Notes'}
                        data_description={data_description[0]}
                        logoIndex={0}
                        isLocked={true}
                    ></Details>
                    <Details
                        courseTitle={'The Birth Lounge Complete Course'}
                        data_description={data_description[1]}
                        logoIndex={1}
                        isLocked={false}
                    ></Details>
                    <Details
                        courseTitle={'Dad Daze Course'}
                        data_description={data_description[2]}
                        logoIndex={2}
                        isLocked={false}
                    ></Details>
                    <Details
                        courseTitle={'The Secret Sauce to Pushing Course'}
                        data_description={data_description[3]}
                        logoIndex={3}
                        isLocked={false}
                    ></Details> */}



                        {/* <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>

                            My Library (Content):-when you click the “book” icon, a menu will pop up that offers: show a completion ring of how much of the content they’ve consumed, lessons, guides, hospital equation, favorites, meditations

                        </p>
                    </div> */}













                        {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={libraryImage_1}></img>

                    </div> */}



                        {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={libraryImage_2}></img>

                    </div> */}





                        {/* <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={libraryImage_3}></img>

                    </div> */}
















                        <div style={{
                            paddingBottom: '2rem',
                        }}></div>



                        <BottomBar></BottomBar>



                    </div>
                )}
            </Layout>
        );

    }


};

//export default Library;

const mapStateToProps = (state) => ({
    LIBRARY_DATA_FROM_SELECTOR: LIBRARY_DATA(state),
});

export default connect(mapStateToProps)(Library);