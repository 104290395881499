// ==========================================
//               IMPORTS
// ==========================================

// REDUX
import { store } from '../../store.js';

// REDUX ACTIONS
import a03_SelectLesson from "../../actions/a03_SelectLesson"

const devModeBool = true
export const selectLesson = async (data) => {

        await store.dispatch(a03_SelectLesson(data));

}



