import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';

import RecursiveReplyThreadComponent from './ReplyThreadComponent'
import { useState, useEffect } from "react";

import exampleImage_1 from './avatar_1.png'


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'; // Or ReplyIcon
import TextField from '@mui/material/TextField';
// {postRepliesObject ? ( ) : ()}


import av1 from './avatars/avatar_01.png'
import av2 from './avatars/avatar_02.png'
import av3 from './avatars/avatar_03.png'
import av4 from './avatars/avatar_04.png'
import av5 from './avatars/avatar_05.png'
import av6 from './avatars/avatar_06.png'



import CONFIG from '../../config/config.js'




const ReplyThreadComponent = (props) => {

    // Extract data from props
    const { data } = props;
    const { recursionLevel } = props;

    // function isNumberEven(number) {
    //     if (number === 0) {
    //         return true; // Zero is even
    //     } else {
    //         let modulusNumber = number % 2
    //         console.log('modulusNumber ', number, modulusNumber)
    //         return number % 2 === 0;
    //     }
    // }

    // let recursiveLayer = isNumberEven(recursionLevel)
    // console.log('recursiveLayer ', recursiveLayer)

    // let layerBackgroundColor = null
    // if (recursiveLayer = true) {
    //     layerBackgroundColor = 'maroon'
    // } else {
    //     layerBackgroundColor = '#bbe7e0'
    // }



    // let layerBackgroundColor = '#eefffc' //'#97cbc2' //'#97cbc2' '#bbe7e0'
    // if (recursionLevel % 2 == 0) {
    //     layerBackgroundColor = '#eefffc' //'rgb(154, 208, 200)' //'#9dd5cc' //'#84b5ad'
    // }

    let layerBackgroundColor = '#eefffc' //'#97cbc2' //'#97cbc2' '#bbe7e0'
    if (recursionLevel > 0 ) {
        layerBackgroundColor = '#eefffc' //'rgb(154, 208, 200)' //'#9dd5cc' //'#84b5ad'
    }

    // 97cbc2   151, 203, 194

    // bbe7e0   187, 231, 224

    //              36  28     30

    //         12  9   10
    // a3d5cc          163 213 204
    // console.log('data ', data)



    const [openReplyBox, setOpenReplyBox] = useState([]);




    const handleclick2 = (item, index) => {
        // let newArray = openReplyBox
        // newArray.push(index)
        // console.log(openReplyBox, newArray)
        // setOpenReplyBox(newArray)
        //setOpenReplyBox(prevState => [...prevState, index]); // Create a new array

    }



    const handleClick = (item, index) => {

        // if (replyButtonText == 'Reply'){
        //     setReplyButtonText('Cancel')
        // } else{
        //     setReplyButtonText('Reply')
        // }

        // const button = document.activeElement; // Get the currently focused element
        // if (button && button.tagName === 'Button') {
        //     button.blur();
        // }



        setOpenReplyBox(prevState => {
            if (prevState.includes(index)) {
                // Index is already in the array, so remove it (close the reply box)
                return prevState.filter(item => item !== index);
            } else {
                // Index is not in the array, so add it (open the reply box)
                return [...prevState, index];
            }
        });
    };






    // useEffect(() => {
    //     // This effect will run whenever `openReplyBox` changes
    //     console.log('openReplyBox updated:', openReplyBox); // Optional: Log for debugging
    //   }, [openReplyBox]); 


    // Yes, there's a crucial point to remember when adding or removing elements from a useState array in React: You must always create a new array and update the state with that new array, rather than directly modifying the existing array.

    // This is because React's state management system relies on immutability to efficiently detect changes and trigger re-renders. Directly mutating the state array using methods like push, pop, splice, etc., will not trigger a re-render, leading to inconsistencies between your state and the UI.


    // const [myArray, setMyArray] = useState([]);

    // const handleAddElement = () => {
    //     const newElement = 'new item';
    //     setMyArray([...myArray, newElement]); // Create a new array with the new element appended
    //     // Or: setMyArray(myArray.concat(newElement)); 
    // };

    const [text, setText] = useState('');




    const [replyButtonText, setReplyButtonText] = useState('Reply');

    const checkIfOpened = (argument) => {
        if (argument) { return 'Cancel' } else { return 'Reply' }
    }

    // const [elementPadding, setElementPadding] = useState('0rem');
    // //let elementPadding = '0rem'
    // console.log('recursionLevel', recursionLevel)
    // if (recursionLevel == 0) {
    //     setElementPadding('2px')
    // }
    // console.log('elementPadding', elementPadding)
    // //     <Button>
    // //     {checkIfOpened(openReplyBox.includes(index))}
    // // </Button>

    // const elementPadding = recursionLevel === 0 ? '2px' : '0rem';


    let elementPadding = '0rem'
    // console.log('recursionLevel', recursionLevel)
    if (recursionLevel == 0) {
        elementPadding = ('0.7rem')
    }





    const [inputValues, setInputValues] = useState({}); // Object to store input values
    const handleInputChange = (index, event) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: event.target.value,
        }));
    };
























    const { onCallback } = props

    const handleSubmit = async (item, index) => {
        console.log('submit', item._id)
        console.log(inputValues); // Access all the input values here


        const parentElementId = item._id
        const content = inputValues[index]
        const username = 'Username'
        const parentCommentId = null

        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP + '/api/community/createComment'

        try {
            const response = await fetch(fetchEndpointURL, { // Adjust the API endpoint as needed
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ parentElementId, content, username, parentCommentId })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Post created successfully:', data);
                // You might want to redirect to a success page or update the UI here

                handleClick(item, index)
                onCallback(data);

            } else {
                console.error('Error creating post:', response.status, response.statusText);
                // Display an error message to the user
            }
        } catch (error) {
            console.error('Error creating post:', error);
            // Display an error message to the user
        }




    };
















    // const formComment = document.getElementById('commentForm');

    // formComment.addEventListener('submit', async (event) => {
    //     event.preventDefault(); // Prevent default form submission

    //     // postId, content, username, parentCommentId
    //     const postId = document.getElementById('commentParentPost').value;
    //     const parentElementId = document.getElementById('commentParentPost').value;
    //     const content = document.getElementById('commentContent').value;
    //     const username = document.getElementById('commentUsername').value;
    //     const parentCommentId = null
    //     try {
    //         const response = await fetch('/api/community/createComment', { // Adjust the API endpoint as needed
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ parentElementId, content, username, parentCommentId })
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log('Post created successfully:', data);
    //             // You might want to redirect to a success page or update the UI here
    //         } else {
    //             console.error('Error creating post:', response.status, response.statusText);
    //             // Display an error message to the user
    //         }
    //     } catch (error) {
    //         console.error('Error creating post:', error);
    //         // Display an error message to the user
    //     }
    // });































    return (
        <div>

            {

                data.childElementArray.map((item, index) => (

                    <div
                        key={`displayItem_${index}`}
                        id={index + 1}
                        style={{
                            //margin: '0.5rem',
                            padding: '0.5rem',
                            paddingLeft: '0.7rem',
                            paddingRight: elementPadding, // '0rem',
                            backgroundColor: layerBackgroundColor, //'#bbe7e0',
                            //borderRadius: '0.5rem',
                            // borderLeft: '2px solid gray',


                        }}
                    >

                        <div style={{
                            //margin: '0.5rem',
                            // padding: '3px',
                            borderRadius: '0.5rem',
                            //borderBottom: '2px solid black',
                            border: '1px solid #9b59b6',
                            padding: '0.5rem',


                        }} >

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <img style={{
                                    width: '2rem',
                                    borderRadius: '1rem'
                                }}
                                    src={exampleImage_1}></img>




                                <Typography style={{ paddingLeft: '0.5rem' }}>
                                    {item.username}
                                </Typography>
                                {/* <Typography>
                                    {item._id}
                                </Typography> */}
                            </div>

                            <Typography
                                style={{
                                    padding: '0.5rem',
                                }}
                            >
                                {item.content}
                            </Typography>
                            {/* <Typography>
                            {item.date}
                        </Typography> */}
                            {/* <Typography>
                            Replies: {item.childElementArray.length.toString()}
                        </Typography> */}

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                                //borderBottom: '2px solid black',
                                //border: '2px solid white',
                            }}>
                                <ThumbUpIcon
                                    sx={{
                                        fontSize: 20,
                                        opacity: 0.6,
                                        color: '#a467cc',
                                        '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                    }}></ThumbUpIcon>
                                <ThumbDownIcon sx={{
                                    fontSize: 20, opacity: 0.6, color: '#a467cc',
                                    '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                }}></ThumbDownIcon>
                                <ChatBubbleOutlineIcon sx={{
                                    fontSize: 20, opacity: 0.8, color: '#a467cc',
                                    '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                }}></ChatBubbleOutlineIcon>
                                <div onClick={() => handleClick(item, index)}
                                    style={{
                                        //backgroundColor: '#bb98d9',
                                        padding: '0.2rem',
                                        borderRadius: '0.2rem',

                                    }}>


                                    <Button variant="outlined"
                                        sx={{
                                            width: '6rem',
                                            backgroundColor: '#bb98d9',
                                            paddingTop: '0.1rem',
                                            paddingBottom: '0rem',
                                            margin: '0rem',
                                            border: 'none',
                                            color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                            opacity: '0.8',
                                            '&:hover': {
                                                backgroundColor: '#9b59b6', //'#97cbc2',
                                                border: 'none',
                                                // borderColor: '#73afa4',
                                                // boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                backgroundColor: '#bb98d9',
                                                border: 'none',
                                                // borderColor: '#73afa4',
                                                // boxShadow: 'none',
                                            },
                                        }}>
                                        {checkIfOpened(openReplyBox.includes(index))}
                                    </Button>


                                </div>









                            </div>


                            {openReplyBox.includes(index) ? (

                                <div>
                                    {/* <Typography>OPENED</Typography> */}

                                    <div style={{ margin: '1rem' }}>
                                        {/* <textarea
                                            style={{ width: '100%', height: '7rem' }}
                                            value={text}
                                            onChange={e => setText(e.target.value)}
                                            placeholder="Enter reply text..."
                                        /> */}

                                        <TextField
                                            label='Reply Text'


                                            value={inputValues[index] || ''}
                                            onChange={event => handleInputChange(index, event)}


                                            multiline
                                            rows={4} // Set initial number of rows
                                            maxRows={8} // Set maximum number of rows (optional)
                                            //value={replyText}
                                            // onChange={handleReplyChange}
                                            variant="outlined" // Or "filled" or "standard"

                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#bb98d9', // Set the default border color
                                                        color: 'green'
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#9b59b6', // Change border color on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#9b59b6', // Change border color when focused
                                                    },
                                                },
                                                '& .MuiInputLabel-root': { // Target the InputLabel
                                                    color: '#bb98d9', // Set the default placeholder color
                                                    '&.Mui-focused': {
                                                        color: '#9b59b6', // Change color when focused (optional)
                                                    },
                                                },
                                            }}
                                            style={{ width: '100%', }}
                                        />



                                        {/* <button onClick={handleSubmit}>Submit</button> */}
                                        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', }}>






                                            {/* <div>
                                                {items.map((item, index) => (
                                                    <div key={index}>
                                                        <TextField
                                                            label={`Input for ${item}`}
                                                            value={inputValues[index] || ''} // Get value from state or default to empty string
                                                            onChange={event => handleInputChange(index, event)}
                                                        />
                                                    </div>
                                                ))}
                                                <Button onClick={handleSubmit}>Submit</Button>
                                            </div> */}



                                            <Button
                                                // onClick={handleSubmit}
                                                onClick={() => handleSubmit(item, index)}


                                                variant="outlined"
                                                sx={{
                                                    //width: '6rem',
                                                    backgroundColor: '#bb98d9',
                                                    paddingTop: '0.1rem',
                                                    paddingBottom: '0rem',
                                                    margin: '0rem',
                                                    border: 'none',
                                                    color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                                    opacity: '0.8',
                                                    '&:hover': {
                                                        backgroundColor: '#9b59b6', //'#97cbc2',
                                                        border: 'none',
                                                        // borderColor: '#73afa4',
                                                        // boxShadow: 'none',
                                                    },
                                                    '&:focus': {
                                                        backgroundColor: '#bb98d9',
                                                        border: 'none',
                                                        // borderColor: '#73afa4',
                                                        // boxShadow: 'none',
                                                    },
                                                }}>


                                                Submit Reply
                                            </Button>
                                        </div>
                                    </div>


                                </div>

                            ) : (

                                <div>

                                </div>

                            )}





                        </div>


                        <div
                            style={{
                                position: 'relative',
                                '&::before': {
                                  content: "''",
                                  position: 'absolute',
                                  top: 0, 
                                  left: 0, 
                                  width: '50%', 
                                  height: '100%',
                                  borderLeft: '2px solid red', 
                                }
                              }}
                        >
                            <RecursiveReplyThreadComponent data={item} recursionLevel={recursionLevel + 1} index={index} onCallback={onCallback}></RecursiveReplyThreadComponent>
                        </div>


                    </div>


                ))}

        </div >
    );
};

export default ReplyThreadComponent;

