

export default function a06_FetchPosts (data) {
    console.log('FETCH_POSTS', data)
    return {
      type: 'FETCH_POSTS',
      payload: data
    };
  };


