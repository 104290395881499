import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { Button } from '@mui/material';




// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
import * as fetchPosts from "../../redux/pagemodules/community/populateTopicPosts.js"
import { COMMUNITY_TOPIC_DATA } from '../../redux/selectors';

import RepliesDisplay from './RepliesDisplay'
import exampleImage_1 from './avatar_1.png'

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'; // Or ReplyIcon
import TextField from '@mui/material/TextField';

import { useState, useEffect, useRef } from "react";

import CONFIG from '../../config/config.js'










import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';



import { useSelector } from 'react-redux';







const PostDisplay = (props) => {


    const { user } = useSelector(state => state.user);

    // Extract data from props
    const data = props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR.topicPosts

    const { selected } = props






    const [openReplyBox, setOpenReplyBox] = useState([]);

    const [inputValues, setInputValues] = useState({}); // Object to store input values
    const handleInputChange = (index, event) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: event.target.value,
        }));
    };




    const { onCallback } = props
    const handleSubmit = async (item, index) => {
        console.log('submit', item._id)
        console.log(inputValues); // Access all the input values here


        const parentElementId = item._id
        const content = inputValues[index]
        const username = 'Username'
        const parentCommentId = null

        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP + '/api/community/createComment'

        try {
            const response = await fetch(fetchEndpointURL, { // Adjust the API endpoint as needed
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ parentElementId, content, username, parentCommentId })
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Post created successfully:', data);
                // You might want to redirect to a success page or update the UI here

                handleClick(item, index)
                onCallback(selected);

            } else {
                console.error('Error creating post:', response.status, response.statusText);
                // Display an error message to the user
            }
        } catch (error) {
            console.error('Error creating post:', error);
            // Display an error message to the user
        }




    };








    const handleClick = (item, index) => {

        setOpenReplyBox(prevState => {
            if (prevState.includes(index)) {
                // Index is already in the array, so remove it (close the reply box)
                return prevState.filter(item => item !== index);
            } else {
                // Index is not in the array, so add it (open the reply box)
                return [...prevState, index];
            }
        });
    };

    const checkIfOpened = (argument) => {
        if (argument) { return 'Cancel' } else { return 'Reply' }
    }







    return (
        <div

            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}

        >






            {
                data &&
                data.length > 0 &&
                data.map((item, index) => (



                    <Card
                        key={`displayItem_${index}`}
                        id={index + 1}
                        style={{
                            margin: '1rem',
                            padding: '0.5rem',
                            backgroundColor: '#eefffc', //'#bbe7e0',
                            borderRadius: '0.5rem',
                        }}
                    >










                        <div

                            style={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}
                        >









                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start'

                                }}
                            >
                                <img style={{
                                    width: '2rem',
                                    borderRadius: '1rem'
                                }}
                                    src={exampleImage_1}></img>


                                {user.email == item.username ? (
                                    <div>
                                        <EditIcon sx={{
                                            fontSize: 20, opacity: 0.6, color: '#a467cc', marginTop: '1rem',
                                            '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                        }}></EditIcon>
                                        <DeleteIcon sx={{
                                            fontSize: 20, opacity: 0.6, color: '#a467cc', marginTop: '1rem',
                                            '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                        }}></DeleteIcon>
                                    </div>

                                ) : (
                                    <div></div>
                                )
                                }






                            </div>









                            <div style={{
                                //margin: '0.5rem',
                                // padding: '3px',
                                borderRadius: '0.5rem',
                                //borderBottom: '2px solid black',
                                ///////border: '1px solid #9b59b6',
                                padding: '0.5rem',
                                width: '100%',


                            }} >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    {/* <img style={{
                                    width: '2rem',
                                    borderRadius: '1rem'
                                }}
                                    src={exampleImage_1}></img> */}




                                    <Typography style={{ paddingLeft: '0.5rem' }}>
                                        {item.username}
                                    </Typography>
                                    {/* <Typography>
                                    {item._id}
                                </Typography> */}

                                    <div>
                                        <Typography style={{ fontSize: '0.6rem', opacity: '0.6' }}>
                                            {item.date}
                                        </Typography>
                                        {/* <EditIcon sx={{
                                            fontSize: 20, opacity: 0.6, color: '#a467cc',
                                            '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                        }}></EditIcon>
                                        <DeleteIcon sx={{
                                            fontSize: 20, opacity: 0.6, color: '#a467cc',
                                            '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                        }}></DeleteIcon> */}

                                    </div>


                                </div>

                                <Typography
                                    style={{
                                        padding: '0.5rem',
                                    }}
                                >
                                    {item.content}
                                </Typography>

                                {/* <Typography>
                            Replies: {item.childElementArray.length.toString()}
                        </Typography> */}

                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                    //borderBottom: '2px solid black',
                                    //border: '2px solid white',
                                }}>
                                    <ThumbUpIcon
                                        sx={{
                                            fontSize: 20,
                                            opacity: 0.6,
                                            color: '#a467cc',
                                            '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                        }}></ThumbUpIcon>
                                    <ThumbDownIcon sx={{
                                        fontSize: 20, opacity: 0.6, color: '#a467cc',
                                        '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                    }}></ThumbDownIcon>
                                    <ChatBubbleOutlineIcon sx={{
                                        fontSize: 20, opacity: 0.8, color: '#a467cc',
                                        '&:hover': { cursor: 'pointer', opacity: 1, color: '#73afa4', }
                                    }}></ChatBubbleOutlineIcon>
                                    <div onClick={() => handleClick(item, index)}
                                        style={{
                                            //backgroundColor: '#bb98d9',
                                            padding: '0.2rem',
                                            borderRadius: '0.2rem',

                                        }}>


                                        <Button variant="outlined"
                                            sx={{
                                                width: '6rem',
                                                backgroundColor: '#bb98d9',
                                                paddingTop: '0.1rem',
                                                paddingBottom: '0rem',
                                                margin: '0rem',
                                                border: 'none',
                                                color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                                opacity: '0.8',
                                                '&:hover': {
                                                    backgroundColor: '#9b59b6', //'#97cbc2',
                                                    border: 'none',
                                                    // borderColor: '#73afa4',
                                                    // boxShadow: 'none',
                                                },
                                                '&:focus': {
                                                    backgroundColor: '#bb98d9',
                                                    border: 'none',
                                                    // borderColor: '#73afa4',
                                                    // boxShadow: 'none',
                                                },
                                            }}>
                                            {checkIfOpened(openReplyBox.includes(index))}
                                        </Button>


                                    </div>









                                </div>


                                {openReplyBox.includes(index) ? (

                                    <div>
                                        {/* <Typography>OPENED</Typography> */}

                                        <div style={{ margin: '1rem' }}>
                                            {/* <textarea
                                            style={{ width: '100%', height: '7rem' }}
                                            value={text}
                                            onChange={e => setText(e.target.value)}
                                            placeholder="Enter reply text..."
                                        /> */}

                                            <TextField
                                                label='Reply Text'


                                                value={inputValues[index] || ''}
                                                onChange={event => handleInputChange(index, event)}


                                                multiline
                                                rows={4} // Set initial number of rows
                                                maxRows={8} // Set maximum number of rows (optional)
                                                //value={replyText}
                                                // onChange={handleReplyChange}
                                                variant="outlined" // Or "filled" or "standard"

                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#bb98d9', // Set the default border color
                                                            color: 'green'
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#9b59b6', // Change border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#9b59b6', // Change border color when focused
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': { // Target the InputLabel
                                                        color: '#bb98d9', // Set the default placeholder color
                                                        '&.Mui-focused': {
                                                            color: '#9b59b6', // Change color when focused (optional)
                                                        },
                                                    },
                                                }}
                                                style={{ width: '100%', }}
                                            />



                                            {/* <button onClick={handleSubmit}>Submit</button> */}
                                            <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', }}>






                                                {/* <div>
                                                {items.map((item, index) => (
                                                    <div key={index}>
                                                        <TextField
                                                            label={`Input for ${item}`}
                                                            value={inputValues[index] || ''} // Get value from state or default to empty string
                                                            onChange={event => handleInputChange(index, event)}
                                                        />
                                                    </div>
                                                ))}
                                                <Button onClick={handleSubmit}>Submit</Button>
                                            </div> */}



                                                <Button
                                                    // onClick={handleSubmit}
                                                    onClick={() => handleSubmit(item, index)}


                                                    variant="outlined"
                                                    sx={{
                                                        //width: '6rem',
                                                        backgroundColor: '#bb98d9',
                                                        paddingTop: '0.1rem',
                                                        paddingBottom: '0rem',
                                                        margin: '0rem',
                                                        border: 'none',
                                                        color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                                        opacity: '0.8',
                                                        '&:hover': {
                                                            backgroundColor: '#9b59b6', //'#97cbc2',
                                                            border: 'none',
                                                            // borderColor: '#73afa4',
                                                            // boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            backgroundColor: '#bb98d9',
                                                            border: 'none',
                                                            // borderColor: '#73afa4',
                                                            // boxShadow: 'none',
                                                        },
                                                    }}>


                                                    Submit Reply
                                                </Button>
                                            </div>
                                        </div>


                                    </div>

                                ) : (

                                    <div>

                                    </div>

                                )}





                            </div>






                        </div>
                        {/* the above div  is the main div for the card, and separates the avatar icon from the rest*/}






















                        <RepliesDisplay data={item}></RepliesDisplay>

                    </Card>







                )
                )}

        </div >
    );
};

// export default PostDisplay;

const mapStateToProps = (state) => ({
    COMMUNITY_TOPIC_DATA_FROM_SELECTOR: COMMUNITY_TOPIC_DATA(state),
});

export default connect(mapStateToProps)(PostDisplay);

