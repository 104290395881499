import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useState, useEffect, useRef } from "react";


import arrow_right from './arrow_right.svg'
import arrow_left from './arrow_left.svg'


import PostDisplay from './PostDisplay'


// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
import * as fetchPosts from "../../redux/pagemodules/community/populateTopicPosts.js"
import { COMMUNITY_TOPIC_DATA } from '../../redux/selectors';


import CONFIG from '../../config/config.js'


import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';


import { useSelector } from 'react-redux';


const CommunityDisplay = (props) => {

    const { user } = useSelector(state => state.user);

    const { data } = props;
    // console.log('CommunityDisplay ', data.communityTopics)
    let renderDisplayItems = data.communityTopics
    //console.log('renderDisplayItems', renderDisplayItems)
    if (renderDisplayItems === undefined) { renderDisplayItems = [] }

    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedOpacity, setSelectedOpacity] = useState(true);
    const [selectedOpacity2, setSelectedOpacity2] = useState(false);



    const handleTopicClick = (item) => {

        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP
        let fetchObject = {
            itemId: item._id,
            url: fetchEndpointURL
        }

        fetchPosts.fetchPosts(fetchObject)

        setSelectedOpacity(false); // Start fade-out animation
        // setSelectedOpacity2(false)
        setTimeout(() => {
            setSelectedTopic(item);
        }, 290); // Delay slightly longer than the transition duration
        setTimeout(() => {
            setSelectedOpacity2(true)
        }, 300);
    };






    const handleTopicUnClick = () => {
        // Start fade-out animation
        setSelectedOpacity2(false)
        setTimeout(() => {
            setSelectedTopic(null);
        }, 290); // Delay slightly longer than the transition duration
        setTimeout(() => {
            setSelectedOpacity(true);
        }, 300);


    };









    const handleCallback2 = (data) => {

        console.log('DATA: ', data)
        // setSelectedTopic(null);

        let tempSelectedTopic = selectedTopic
        setSelectedOpacity2(false)
        setTimeout(() => {
            setSelectedTopic(null);
        }, 290); // Delay slightly longer than the transition duration
        setTimeout(() => {
            setSelectedOpacity(true);
        }, 300);
        //setSelectedTopic(tempSelectedTopic);
        //handleTopicClick()


        // Start fade-out animation
        // setSelectedOpacity2(false)
        setTimeout(() => {
            setSelectedOpacity(false);
            setSelectedTopic(data);
        }, 590); // Delay slightly longer than the transition duration
        setTimeout(() => {
            setSelectedOpacity2(true)
        }, 600);



    };








    const handleCallback = (data) => {

        //let tempSelectedTopic = selectedTopic
        // setSelectedTopic(null);
        handleTopicClick(selectedTopic)
        // setTimeout(() => {
        //     setSelectedTopic(tempSelectedTopic);
        // }, 50);

    };






    const [openReplyBox, setOpenReplyBox] = useState(false);

    const [inputValues, setInputValues] = useState(''); // Object to store input values

    const handleInputChange = (event) => {
        setInputValues(
            event.target.value,
        );
    };


    const handleSubmit = async (topic) => {

        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP + '/api/community/createPost'

        //const username = user.first_name + ' ' +  user.last_name + ' (' + user.email + ')'
        const username = user.email
       const content = inputValues
        const topicId = topic._id
        try {
            const response = await fetch(fetchEndpointURL, { // Adjust the API endpoint as needed
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, content, topicId })
            });

            if (response.ok) {


                const data = await response.json();
                console.log('Post created successfully:', data);
                handleTopicClick(topic)
                setOpenReplyBox(false)
                setInputValues('');


                // You might want to redirect to a success page or update the UI here
            } else {
                console.error('Error creating post:', response.status, response.statusText);
                // Display an error message to the user
            }
        } catch (error) {
            console.error('Error creating post:', error);
            // Display an error message to the user
        }

    };




















    return (
        <div



        >
            {selectedTopic ? (
                <div



                >
                    <div



                        style={{

                            backgroundColor: 'inherit', //'#d1eee8',//'#97cbc2',
                            marginBottom: '1rem',
                            opacity: selectedOpacity2 ? 1 : 0, //selectedTopic ? 0 : 1,
                            transition: 'opacity 0.3s ease-in-out', // Add a fade-out transition
                            borderRadius: '1rem',
                            padding: '0rem',
                        }}

                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignitems: 'center',
                            padding: '1rem',
                        }}>
                            <div
                            // style={{backgroundColor:'#bbe7e0'}}
                            >
                                <img src={arrow_left} alt={`Icon`}
                                    onClick={() => handleTopicUnClick()}
                                    style={{
                                        borderRadius: '1rem',
                                        backgroundColor: '#bb98d9', //'#bbe7e0',
                                        opacity: '80%',
                                        padding: '0.5rem',
                                        width: '3.5rem',
                                        height: '3.5rem',
                                        margin: '0.5rem',
                                        marginRight: '2rem',
                                    }} />
                            </div>
                            <div>

                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '1rem',
                                    }}>
                                    {selectedTopic.title}
                                </Typography>
                                {/* <Typography>
                                    {selectedTopic._id}
                                </Typography> */}
                                <Typography>
                                    {selectedTopic.description}
                                </Typography>

                            </div>
                        </div>


                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>






                            {openReplyBox ? (




                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>





                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                        }}
                                    >

                                        <Button
                                            onClick={() => setOpenReplyBox(false)}

                                            variant="outlined"
                                            sx={{
                                                width: '6rem',
                                                backgroundColor: '#bb98d9',
                                                paddingTop: '0.1rem',
                                                paddingBottom: '0rem',
                                                margin: '1rem',
                                                border: 'none',
                                                color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                                opacity: '0.8',
                                                '&:hover': {
                                                    backgroundColor: '#9b59b6', //'#97cbc2',
                                                    border: 'none',
                                                    // borderColor: '#73afa4',
                                                    // boxShadow: 'none',
                                                },
                                                '&:focus': {
                                                    backgroundColor: '#bb98d9',
                                                    border: 'none',
                                                    // borderColor: '#73afa4',
                                                    // boxShadow: 'none',
                                                },
                                            }}>
                                            Cancel
                                        </Button>



                                    </div>




                                    {/* <Typography>OPENED</Typography> */}

                                    <div style={{ margin: '1rem' }}>
                                        {/* <textarea
                                    style={{ width: '100%', height: '7rem' }}
                                    value={text}
                                    onChange={e => setText(e.target.value)}
                                    placeholder="Enter reply text..."
                                /> */}

                                        <TextField
                                            label=''


                                            value={inputValues || ''}
                                            onChange={event => handleInputChange(event)}


                                            multiline
                                            rows={4} // Set initial number of rows
                                            maxRows={8} // Set maximum number of rows (optional)
                                            //value={replyText}
                                            // onChange={handleReplyChange}
                                            variant="outlined" // Or "filled" or "standard"

                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#bb98d9', // Set the default border color
                                                        color: 'green'
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#9b59b6', // Change border color on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#9b59b6', // Change border color when focused
                                                    },
                                                },
                                                '& .MuiInputLabel-root': { // Target the InputLabel
                                                    color: '#bb98d9', // Set the default placeholder color
                                                    '&.Mui-focused': {
                                                        color: '#9b59b6', // Change color when focused (optional)
                                                    },
                                                },
                                            }}
                                            style={{ width: '100%', }}
                                        />



                                        {/* <button onClick={handleSubmit}>Submit</button> */}
                                        <div style={{ margin: '1rem', display: 'flex', justifyContent: 'center', }}>






                                            {/* <div>
                                        {items.map((item, index) => (
                                            <div key={index}>
                                                <TextField
                                                    label={`Input for ${item}`}
                                                    value={inputValues[index] || ''} // Get value from state or default to empty string
                                                    onChange={event => handleInputChange(index, event)}
                                                />
                                            </div>
                                        ))}
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </div> */}



                                            <Button
                                                // onClick={handleSubmit}
                                                onClick={() => handleSubmit(selectedTopic)}


                                                variant="outlined"
                                                sx={{
                                                    //width: '6rem',
                                                    backgroundColor: '#bb98d9',
                                                    paddingTop: '0.1rem',
                                                    paddingBottom: '0rem',
                                                    margin: '0rem',
                                                    border: 'none',
                                                    color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                                    opacity: '0.8',
                                                    '&:hover': {
                                                        backgroundColor: '#9b59b6', //'#97cbc2',
                                                        border: 'none',
                                                        // borderColor: '#73afa4',
                                                        // boxShadow: 'none',
                                                    },
                                                    '&:focus': {
                                                        backgroundColor: '#bb98d9',
                                                        border: 'none',
                                                        // borderColor: '#73afa4',
                                                        // boxShadow: 'none',
                                                    },
                                                }}>


                                                Post Comment
                                            </Button>
                                        </div>
                                    </div>


                                </div>




                            ) : (


                                <Button
                                    onClick={() => setOpenReplyBox(true)}

                                    variant="outlined"
                                    sx={{
                                        width: '6rem',
                                        backgroundColor: '#bb98d9',
                                        paddingTop: '0.1rem',
                                        paddingBottom: '0rem',
                                        margin: '1rem',
                                        border: 'none',
                                        color: 'black',// '#0e9789',   borderColor: '#0e9789',
                                        opacity: '0.8',
                                        '&:hover': {
                                            backgroundColor: '#9b59b6', //'#97cbc2',
                                            border: 'none',
                                            // borderColor: '#73afa4',
                                            // boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            backgroundColor: '#bb98d9',
                                            border: 'none',
                                            // borderColor: '#73afa4',
                                            // boxShadow: 'none',
                                        },
                                    }}>
                                    Write Comment
                                </Button>


                            )}


                        </div>


                        <PostDisplay selected={selectedTopic} onCallback={handleCallback}></PostDisplay>



                    </div>









                </div>
            ) : (
                <div>
                    {
                        renderDisplayItems &&
                        renderDisplayItems.length > 0 &&
                        renderDisplayItems.map((item, index) => (
                            <Card
                                key={`displayItem_${index}`}
                                id={index + 1}
                                onClick={() => { handleTopicClick(item) }}
                                style={{
                                    backgroundColor: '#d1eee8', //97cbc2
                                    marginBottom: '1rem',
                                    opacity: selectedOpacity ? 1 : 0, //selectedTopic ? 0 : 1,
                                    transition: 'opacity 0.3s ease-in-out', // Add a fade-out transition
                                    borderRadius: '1rem',
                                    padding: '1rem',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '1rem',
                                    }}>
                                    {item.title}
                                </Typography>
                                {/* <Typography>
                                    {item._id}
                                </Typography> */}
                                <Typography>
                                    {item.description}
                                </Typography>
                            </Card>
                        ))}
                </div >
            )}
        </div>
    );

};

export default CommunityDisplay;




































// onClick={() => {
//     setSelectedOpacity(0)
//     setTimeout(() => {
//         setSelectedTopic(item);
//     }, 200);
// }
// }


// return (
//     <div>
//         {
//             renderDisplayItems &&
//             renderDisplayItems.length > 0 &&
//             renderDisplayItems.map((item, index) => (
//                 <Card

//                     key={`displayItem_${index}`}
//                     id={index + 1}

//                     style={{
//                         marginBottom: '1rem',
//                     }}
//                 >
//                     <Typography>
//                         {item.title}
//                     </Typography>
//                     <Typography>
//                         {item.description}
//                     </Typography>
//                 </Card>
//             ))}
//     </div >
// );

//   <ul>
//   {topics.map(topic => (
//     <li
//       key={topic.id}
//       onClick={() => setSelectedTopic(topic)}
//       style={{
//         opacity: selectedTopic ? 0 : 1,
//         transition: 'opacity 0.3s ease-in-out' // Add a fade-out transition
//       }}
//     >
//       {topic.title}
//     </li>
//   ))}
// </ul>



// return (
//     <div>
//       {selectedTopic ? ( 
//         // Render the selected topic and its subcomponent
//         <div>
//           <h2>{selectedTopic.title}</h2>
//           {/* ... other topic details */}
//           <SubComponent topic={selectedTopic} /> {/* Pass the selected topic data to the subcomponent */}
//         </div>
//       ) : ( 
//         // Render the list of all topics
//         <ul>
//           {topics.map(topic => (
//             <li 
//               key={topic.id} 
//               onClick={() => setSelectedTopic(topic)}
//               style={{ 
//                 opacity: selectedTopic ? 0 : 1, 
//                 transition: 'opacity 0.3s ease-in-out' // Add a fade-out transition
//               }}
//             >
//               {topic.title}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );

























// Overall, the `CommunityDisplay` component looks good and implements the core functionality you described earlier. However, there are a few areas where you might consider making improvements:

// **Positives**

// * **Clear structure:** The component has a clear separation between the selected topic view and the list of topics, making the logic easier to follow.
// * **State management:** It uses `useState` effectively to manage the `selectedTopic` and `selectedOpacity`, which drives the conditional rendering and animation.
// * **Animation:** The fade-out animation on unselected topics adds a nice touch to the user experience.
// * **API request trigger:** It seems like you're planning to trigger an API request within a `useEffect` hook (not shown here) based on the `selectedTopic`, which is the correct approach.

// **Potential Improvements**

// 1. **Error Handling:** 
//    * Consider adding error handling within the `handleTopicClick` function to gracefully handle cases where the API request fails to fetch the posts for the selected topic. You could display an error message to the user or provide a way to retry the request.

// 2. **Loading State:** 
//    * While the API request is in progress, you might want to display a loading indicator or some visual feedback to the user. You can introduce a `loading` state variable and conditionally render a loading spinner or message.

// 3. **Accessibility:**
//    * Ensure that your component is accessible to users with disabilities. 
//    * Use appropriate ARIA attributes and semantic HTML elements to provide screen reader support and keyboard navigation.

// 4. **Code Organization:**
//    * As your component grows, you might want to extract some of the logic into separate functions or custom hooks to improve readability and maintainability.

// 5. **Animation Refinement (Optional):**
//    * You could experiment with different animation durations, easing functions, or even other animation properties (like `transform`) to fine-tune the visual effect.

// **Example with Error Handling and Loading State**

// ```javascript
// const CommunityDisplay = (props) => {
//   // ... (rest of your code)

//   const [isLoading, setIsLoading] = useState(false); 
//   const [error, setError] = useState(null);

//   const handleTopicClick = (item) => {
//     setIsLoading(true); // Show loading indicator
//     setSelectedOpacity(0); 
//     setTimeout(() => {
//       setSelectedTopic(item);
//       fetch(`/api/community/getPosts?topicId=${item.id}`)
//         .then(response => response.json())
//         .then(posts => {
//           onTopicSelect(item, posts);
//           setIsLoading(false); // Hide loading indicator
//         })
//         .catch(error => {
//           console.error('Error fetching posts:', error);
//           setError('Failed to load posts. Please try again.'); 
//           setIsLoading(false);
//         });
//     }, 300);
//   };

//   return (
//     <div>
//       {isLoading && <div>Loading...</div>}
//       {error && <div>{error}</div>} 
//       {/* ... rest of your component */}
//     </div>
//   );
// };
// ```

// These suggestions should help you enhance the functionality, user experience, and maintainability of your `CommunityDisplay` component! 
