import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { resetRegistered, login } from 'features/user';
import Layout from 'components/Layout2';

import { useLocation } from 'react-router-dom';




const LoginPage = () => {
	const dispatch = useDispatch();









	const location = useLocation();
	///////const [initialPath, setInitialPath] = useState(location.pathname);    '/home'     {initialPath}
	const [initialPath, setInitialPath] = useState('/home');
	////const [initialPath, setInitialPath] = useState(location.pathname);
	console.log('location.pathname ', location.pathname)
	// useEffect(() => {
	// 	if (location.pathname !== '/library' && location.pathname !== initialPath) { 
	// 	  setInitialPath(location.pathname); // Update initialPath to the current path for subsequent navigation within the app. 
	// 	  window.location.href = '/library';  // Redirect to the library page
	// 	}
	//   }, [location]);





	const { loading, isAuthenticated, registered } = useSelector(
		state => state.user
	);

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});







	if (location.pathname !== '/home' && location.pathname !== '/login') {
		setInitialPath(location.pathname)
		console.log('setting initial path to ', location.pathname)
	}













	useEffect(() => {
		if (registered) dispatch(resetRegistered());
	}, [registered]);

	const { email, password } = formData;

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = e => {
		e.preventDefault();

		// setInitialPath('/home')
		dispatch(login({ email, password }));
	};




	if (isAuthenticated) return <Navigate to={initialPath}  />;

	/////////   if (isAuthenticated) return <Navigate to='/home' />;

	// if (isAuthenticated) {
	// 	window.location.href = '/home'; // Direct redirect
	// }

	return (
		<Layout 
		// title='The Birth Lounge | Login' content='Login page'
		>

			<div >

			
			<h1 style={{ textAlign: 'center', }}>Log In</h1>
			<form className='mt-5' onSubmit={onSubmit}>
				<div className='form-group'>
					<label className='form-label' htmlFor='email'>
						Email
					</label>
					<input
						className='form-control'
						type='email'
						name='email'
						onChange={onChange}
						value={email}
						required
					/>
				</div>
				<div className='form-group mt-3'>
					<label className='form-label' htmlFor='password'>
						Password
					</label>
					<input
						className='form-control'
						type='password'
						name='password'
						onChange={onChange}
						value={password}
						required
					/>
				</div>
				{loading ? (
					<div className='spinner-border text-primary' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
				) : (
					<div style={{ width:'100%', display: 'flex', justifyContent: 'center',  }}>
						<button className='btn mt-4' style={{ backgroundColor: '#0E9789', }}>Submit</button>
					</div>

				)}
			</form>
			</div>
		</Layout>
	);
};

export default LoginPage;




// <button className='btn btn-primary mt-4'>Login</button>