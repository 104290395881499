import Layout from 'components/Layout2';
import '../fonts/font.css'

import bgImg from './background_1.jpg'
import Navbar from 'components/Navbar2';
import { useNavigate } from 'react-router-dom';

import logo from './birth_lounge_logo_3.png'





const HomePage = () => {



	const navigate = useNavigate();

	const handleButtonClick = (pathURL) => {
		// navigate('/library'); // Replace with your desired route
		navigate(pathURL); // Replace with your desired route
	};




	return (



		<div style={{ backgroundColor: '#73AFA4', minHeight: '170vh' }} >



			<Navbar />
			<div style={{ backgroundColor: '#73AFA4' }}>



				{/* <div
					style={{
						backgroundImage: `url(${bgImg})`,
						opacity: '15%',
						position: 'absolute',
						height: '100%',
						width: '100%',
						backgroundSize: 'cover',
						backgroundRepeat: 'repeat'
					}}>

				</div> */}


				<h1
					//className='mb-5'
					style={{
						paddingTop: '3rem',
						fontFamily: 'imported-font-sacramento',
						//fontWeight: 'italic',
						fontSize: '3rem',
						textAlign: 'center',
						marginBottom: '1rem',
					}}
				>Hello!</h1>
				<p
					style={{

						fontFamily: 'imported-font-poppins',
						//fontWeight: 'italic',
						fontSize: '1.5rem',
						textAlign: 'center',
						marginBottom: '0rem',

						padding: '1rem',
					}}
				>I'm HeHe, a doula with a Master's Degree in Human Development & Family Studies. I am obsessed with helping pregnant moms have positive hospital births without trauma or feeling pressured into decisions that don't feel aligned to you!</p>


				<p
					style={{

						fontFamily: 'imported-font-poppins',
						//fontWeight: 'italic',
						fontSize: '1.5rem',
						textAlign: 'center',
						marginBottom: '0rem',

						padding: '1rem',
					}}
				>
					You want more control, not interventions, right?</p>



				<p
					style={{

						fontFamily: 'imported-font-poppins',
						//fontWeight: 'italic',
						fontSize: '1.5rem',
						textAlign: 'center',
						marginBottom: '0rem',
						padding: '1rem',
					}}
				>Imagine having all the evidence-based education to navigate intimidating hospital policy and confidently make decisions about your pregnancy and in labor!
				</p>










				<div
					style={{

						width: '100%',
						height: '9rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						borderRadius: '1rem',
					}}
				>

					<div onClick={() => handleButtonClick('/register')}
						style={{
							marginTop: '3rem',
							marginBottom: '3rem',
							padding: '1rem',
							backgroundColor: '#97CBC2',
							width: '9rem',
							height: '9rem',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							borderRadius: '1rem',
							cursor:'pointer',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<button
								style={{
									backgroundColor: '#97CBC2',
									border: 'none',
								}}
							>
								<img style={{
                                        height: '6rem',
                                        width: '6rem',
                                        align: 'center',
                                        backgroundColor: '#97CBC2',
                                    }}
                                        src={logo} />
							</button>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<p style={{
								fontWeight: 'bold', textAlign: 'center',
							}}>Join Now!</p>
						</div>
					</div>




					{/* <div
						onClick={() => handleButtonClick("/course")}
						style={{
							backgroundColor: "#97CBC2",
							width: "9rem",
							height: "4rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "1rem",
							cursor: "pointer",
							marginBottom: '2rem',
						}}
					>
						<img style={{
							height: '6rem',
							width: '6rem',
							align: 'center',
							backgroundColor: '#97CBC2',
						}}
							src={logo} />
						<p
							style={{
								fontWeight: "bold",
								margin: "0", // Remove padding for better vertical centering
							}}
						>
							Back
						</p>
					</div> */}




				</div>












			</div>
		</div>



	);
};

export default HomePage;




/*


hello color: color: #0e9789;

*/