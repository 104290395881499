

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import SingleModule from './SingleModule'
import '../../fonts/font.css'


const CourseModuleRender = (props) => {

    // Extract data from props
    const { data } = props;
    let renderDisplayItems = data.selectedCourse
    console.log('renderDisplayItems', renderDisplayItems)
    if (renderDisplayItems === undefined) { renderDisplayItems = [] }

    return (
        <div style={{ fontFamily: 'imported-font-varela', width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: "wrap",
                //borderWidth: '2px',
                //borderColor: '#edece8',
                //borderStyle: 'solid',
                //paddingBottom: '1rem',
                marginBottom: '1rem',
            }}>
                <div style={{
                    width: '100%', height: 'auto'
                }}
                >



                    <div 
                    
                    style={{
                        backgroundColor: '#A467CC', // A467CC BB98D9 9B59B6
                        width: '100%', height: '3px',
                        borderRadius: '2px',
                        opacity: '50%',
                    }}
                    
                    >

                    </div>

                    {renderDisplayItems.map((item, index) => (

                        <div

                            key={`displayItem_${index}`}

                        >

                            {/* <p>ITEM, {index} </p> */}
                            <SingleModule data={item} ></SingleModule>

                        </div>


                        // <CourseDetails

                        //     key={`displayItem_${index}`}
                        //     id={index + 1}

                        //     course_id={item.id}
                        //     courseTitle={item.course_title}
                        //     data_description={item.course_description}
                        //     logoIndex={item.course_logo}
                        //     isLocked={false}
                        // ></CourseDetails>



                        // <ResultItem
                        //     key={`displayItem_${index}`}
                        //     id={index + 1}
                        // data={item}
                        // ></ResultItem>
                        // <div>{item.course_title}</div>



                    ))}





                </div>


            </div>
        </div >
    );
};

export default CourseModuleRender;
