import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';



import lesson_1 from './lesson_1.png';
import { useNavigate } from 'react-router-dom';




import cute_color_icons from './cute_color_icons.png'
import favorite_icon from './favorite.png'
import icons8_downloads from './icons8-downloads-64.png'
import icons8_favorite from './icons8-favorite-64.png'
import icons8_paper from './icons8-paper-64.png'
import icons8_quiz from './icons8-quizizz-64.png'
import icons8_video from './icons8-video-64.png'
import notebook_icon from './notebook-5.png'
import paper_icon from './paper-1.png'
import vid_01 from './vid_01.mp4'




// REDUX
import { connect } from 'react-redux';
import { LESSON_DATA } from '../../redux/selectors';
// import { COURSE_DATA } from '../../redux/selectors';




import parse from 'html-react-parser'

import React, { useState, useRef, useEffect } from 'react';
import zIndex from '@mui/material/styles/zIndex';











const Lesson = (props) => {



    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL); // Replace with your desired route
    };












































    // const [showIcon, setShowIcon] = useState(true);
    // const videoRef = useRef(null); // Create a reference to the video element

    // const handleVideoClick = () => {
    //     console.log('hiding icon')
    //     setShowIcon(false);
    //     if (videoRef.current) {
    //         videoRef.current.play(); // Programmatically play the video
    //     }
    // };


    const videoRef = useRef(null);

    useEffect(() => {
        // This useEffect hook runs after the component renders
        if (videoRef.current) {
            const playButton = videoRef.current.querySelector('.controls button');
            if (playButton) {
                playButton.style.display = 'none'; // Hide the default play button
            }
        }
    }, []);



    const lesson_data = props.LESSON_DATA_FROM_SELECTOR.selectedLesson



    // const course_data = props.COURSE_DATA_FROM_SELECTOR.selectedLesson


    // console.log('lesson_data ', lesson_data)

    const { isAuthenticated, user, loading } = useSelector(state => state.user);



    // const content = lesson_data
    // const contentRef = useRef(null);
    // const pageRef = useRef(null); // Ref for a single page
    // const [pageCount, setPageCount] = useState(1);
    // const [pageContent, setPageContent] = useState([]);

    // useEffect(() => {
    //   const calculatePageBreaks = () => {
    //     const pages = [];
    //     let currentPageContent = '';
    //     const pageHeight = 80 * window.innerHeight / 100; // 80vh
    //     const pageElement = pageRef.current;

    //     // Reset page element to empty 
    //     pageElement.innerHTML = "";

    //     // Loop through content elements (paragraphs, headings, etc.)
    //     for (let child of contentRef.current.children) {
    //       pageElement.appendChild(child.cloneNode(true)); // Add a copy of the child to the page

    //       // Check if page overflows
    //       if (pageElement.scrollHeight > pageHeight) {
    //         pageElement.removeChild(child); // Remove the overflowing child
    //         pages.push(currentPageContent); // Store the content for the current page
    //         currentPageContent = child.outerHTML; // Start the next page with the overflowing child
    //         pageElement.innerHTML = currentPageContent; // Reset the page with this child
    //       } else {
    //         currentPageContent += child.outerHTML; // Accumulate content for the current page
    //       }
    //     }

    //     // Add the last page's content
    //     pages.push(currentPageContent);

    //     setPageCount(pages.length);
    //     setPageContent(pages);
    //   };

    //   calculatePageBreaks();
    //   window.addEventListener('resize', calculatePageBreaks); // Update on resize
    //   return () => window.removeEventListener('resize', calculatePageBreaks);
    // }, [content]);


























    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Lesson page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>



                    <div
                        onClick={() => handleButtonClick("/course")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "9rem",
                            height: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom: '2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            Back
                        </p>
                    </div>



                    {/* <div
                        onClick={() => handleButtonClick("/coursemodule")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "9rem",
                            height: "4rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom: '2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            coursemodule
                        </p>
                    </div> */}





                    <h3 style={{ textAlign: 'center', }}>{lesson_data.lesson_title} </h3>
                    {/* <p>Module: {lesson_data.lesson_title}</p> */}



                    {
                        lesson_data.content_summary && (
                            <div
                                style={{
                                    // backgroundColor: '#97CBC2',
                                    padding: '1rem',
                                    marginBottom: '1rem',
                                    fontSize: '1.3rem',
                                    borderRadius: '0.5rem',
                                }}
                            >
                                <p>{lesson_data.content_summary}</p>
                                {/* {parse(lesson_data.content)} */}
                            </div>
                        )}






                    {/* <div>
                        <p
                            style={{
                                fontSize: '1.3rem',
                            }}
                        >
                            This page will query the server for data for the entire lesson. This will include any notes to be rendered on the page, the video URL to be accessed from the CDN and rendered on the page, and links to any downloadable materials. This will be available to authorized users only unless the lesson has been marked as 'Available in Preview'. Preview lessons will be accessible by all users and rendered without a lock icon on the course module page.
                        </p>
                    </div> */}






                    {/* <div class="video-container">
  <video width="100%" height="auto" controls>
    <source src="" type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  <div class="video-icon"></div> 
</div> */}



                    {/* <div
                        style={{

                            backgroundColor: '#BBE7E0',
                            position: 'relative',
                            borderRadius: '0.5rem',
                            

                        }}>
                        <video
                            ref={videoRef}
                            onClick={handleVideoClick}
                            width="100%" height="auto" controls



                            style={{
                                borderRadius: '0.5rem',
                                padding: '0.5rem',
                                cursor: 'pointer',
                            }}>


                            <source src={vid_01} type="video/mp4" />
                        </video>


                        {showIcon && ( // Conditionally render the icon
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundImage: `url(${icons8_video})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                }}></div>
                        )}


                    </div> */}


                    {/* <div style={{
                        backgroundColor: '#BBE7E0',
                        borderRadius: '0.5rem',
                    }}>
                        <video
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '0.5rem',
                                padding: '0.5rem',
                                cursor: 'pointer',
                                // Styles to hide default controls and display custom icon:
                                // WebkitMediaControlsPlayButton
                                '::WebkitMediaControlsPlayButton': { display: 'none' },
                                '::before': {
                                    content: "''",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100px',
                                    height: '100px',
                                    backgroundImage: `url(${icons8_video})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    zIndex: 9999,
                                },
                            }}
                            controls
                        >
                            <source src={vid_01} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div> */}



                    {/* <div style={{
                        backgroundColor: '#BBE7E0',
                        borderRadius: '0.5rem',
                    }}>
                        <video
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '0.5rem',
                                padding: '0.5rem',
                                cursor: 'pointer',
                                // Styles to hide default controls and display custom icon:
                                '::WebkitMediaControlsStartPlaybackButton': { display: 'none' }, // Corrected selector
                                '::WebkitMediaControlsOverlayPlayButton': { display: 'none' },
                                '::before': {
                                    content: "''",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100px',
                                    height: '100px',
                                    backgroundImage: `url(${icons8_video})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    zIndex: 2, // Sufficient z-index value
                                },
                            }}
                            controls
                        >
                            <source src={vid_01} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div> */}


                    <div style={{
                        backgroundColor: '#BBE7E0',
                        borderRadius: '0.5rem',
                    }}>
                        <video
                            ref={videoRef}
                            controls
                            // ={false}
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '1rem',
                                padding: '0.5rem',
                                cursor: 'pointer',
                                // '::before': {
                                //     content: "''",
                                //     position: 'absolute',
                                //     top: '50%',
                                //     left: '50%',
                                //     transform: 'translate(-50%, -50%)',
                                //     width: '100px',
                                //     height: '100px',
                                //     backgroundImage: `url(${icons8_video})`,
                                //     backgroundRepeat: 'no-repeat',
                                //     backgroundPosition: 'center',
                                //     zIndex: 9000,
                                // },
                            }}
                        >
                            <source src={lesson_data.video_url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>







                    <div
                        style={{
                            height: '2rem'
                        }}></div>











                    {/* <div style={{
                        backgroundImage: `url(${notebook_icon})`, // Your background image URL
                        backgroundSize: 'cover', // This will scale the image to cover the entire div
                        backgroundPosition: 'center', // Optional, but ensures image is centered
                        borderRadius: '0.5rem',
                        width: '90%', // This sets the width to 90% of the parent container
                        maxWidth: '100%', // Prevents the div from exceeding the parent container's width
                        height: '80vh', // Maintain the desired height
                        margin: '0 auto', // This centers the div horizontally with equal margins on both sides (5% each)
                        opacity: '80%',
                        overflow: 'hidden',
                    }}>
                        <div style={{
                            margin: '2rem', // This centers the div horizontally with equal margins on both sides (5% each)
                        }}>

                            {parse(lesson_data.html_note_content)}

                        </div>

                    </div> */}









                    {/* <div style={{
      width: '340px',  
      height: '440px', 
      backgroundColor: '#F2E6D9', 
      border: '2px solid #996633', 
      borderRadius: '8px',
      boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
    }}>
         {parse(lesson_data.html_note_content)}
    </div> */}












                    {
                        lesson_data.html_note_content && (

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                border: '1px solid #BB98D9',
                                backgroundColor: '#BBE7E0',
                                borderRadius: '1rem',
                                padding: '2rem',
                                boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)',
                            }}>
                                <div style={{
                                    width: '90%',
                                    height: '90%',
                                    backgroundColor: '#BBE7E0',
                                    backgroundImage: `repeating-linear-gradient(
                                to bottom,
                                #BBE7E0 0%,
                                #BBE7E0 93%,
                                #BB98D9 100%
                                )`,
                                    backgroundSize: '100% 30px', // Key change here!
                                    backgroundPosition: '20px 40px', // Start the lines a bit from the edge and below the border

                                }}>
                                    <div style={{
                                        margin: '0rem', // This centers the div horizontally with equal margins on both sides (5% each)
                                    }}>
                                        {parse(lesson_data.html_note_content)}
                                    </div>
                                </div>
                            </div>


                        )}












                    <div
                        style={{
                            height: '2rem'
                        }}></div>










                    {/* <div style={{
                        backgroundImage: `url(${notebook_icon})`,
                        borderRadius: '0.5rem',
                        width: 'auto',
                        height: '80vh',
                    }}>

                    </div> */}


                    {/* 
                    <div

                        style={{
                            backgroundImage: `url(${icons8_video})`,
                            // backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            // minHeight: '100vh', // Optional: Ensure the background covers the viewport
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <video width="100%" height="auto" controls>
                            <source src="" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div> */}





























                    <div style={{
                        paddingBottom: '5rem',
                    }}></div>

                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

//export default Lesson;

const mapStateToProps = (state) => ({
    LESSON_DATA_FROM_SELECTOR: LESSON_DATA(state),
    // COURSE_DATA_FROM_SELECTOR: COURSE_DATA(state),
});

export default connect(mapStateToProps)(Lesson);





























/*








                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img src={lesson_1}></img>
                    </div>














                    <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>
                            Some suggested icons for notes, video, downloads, favorites, quizzes
                        </p>
                    </div>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={icons8_paper}></img>


                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={icons8_video}></img>

                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={icons8_downloads}></img>







                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>

                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={icons8_favorite}></img>

                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={favorite_icon}></img>


                        <img style={{
                            width: '5rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={icons8_quiz}></img>

                    </div>







                    <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>
                            Some suggested notebook paper icons for clickable panels
                        </p>
                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>

                        <img style={{
                            width: '10rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={notebook_icon}></img>



                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>


                        <img style={{
                            width: '10rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={paper_icon}></img>

                    </div>







                    <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>
                            Lowered Opacity
                        </p>
                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>

                        <img style={{
                            width: '10rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                            opacity: '60%',
                        }}
                            src={notebook_icon}></img>



                    </div>


                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>


                        <img style={{
                            width: '10rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                            opacity: '60%',
                        }}
                            src={paper_icon}></img>

                    </div>

















                    <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>
                            Some additional 'cute color' icons from icons8.com
                        </p>
                    </div>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '20rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={cute_color_icons}></img>

                    </div>
















































                                        <div style={{

                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        border: '1px solid #BB98D9',
                        backgroundColor: '#BBE7E0',
                        borderRadius: '1rem',
                        padding: '2rem',
                        boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)',
                    }}>




                        <div style={{


                            width: '90%',
                            height: '90%',
                            backgroundColor: '#BBE7E0',

                            // borderRadius: '8px',
                            // boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
                            // Lines:
                            //backgroundImage: 'repeating-linear-gradient(to bottom, transparent, transparent 8px, #ddd 8px, #ddd 9px)',
                            backgroundImage: `repeating-linear-gradient(


                                to bottom,
                                #BBE7E0 0%,
                                #BBE7E0 93%,
                                #BB98D9 100%


    
                                )`,

                            // to bottom,
                            // transparent,  
                            // transparent 18px, 
                            // #fff 16px,      
                            // #fff 18px    
                            backgroundSize: '100% 30px', // Key change here!
                            backgroundPosition: '20px 40px', // Start the lines a bit from the edge and below the border



                            // width: '90%',
                            // height: '100vh',
                            // backgroundColor: '#BBE7E0',
                            // borderRadius: '8px',
                            // boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
                            // backgroundImage: `repeating-linear-gradient(
                            //     to bottom,
                            //     transparent 10px,  
                            //     transparent 11px, 
                            //     #fff 10px,      
                            //     #fff 11px        
                            //     )`,
                            // //backgroundSize: '100% 40px',
                            // backgroundPosition: '20px 40px',


                            // width: '90%',
                            // height: '100vh',
                            // backgroundColor: '#BBE7E0',//'#F2E6D9',
                            // // border: '2px solid #996633',
                            // borderRadius: '8px',
                            // boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.3)',
                            // // Lines:
                            // backgroundImage: `repeating-linear-gradient(
                            //     to bottom,
                            //     transparent 10px,  
                            //     transparent 15px, 
                            //     #fff 10px,  
                            //     #fff 11px   
                            //   )`,
                            //   backgroundSize: '100% 40px', // Adjust the 21px to match the total gradient size
                            // backgroundPosition: '20px 40px', // Start the lines a bit from the edge and below the border
                            // // Holes:
                            // position: 'relative', // To position the holes using absolute positioning
                            // overflow: 'hidden',



                            // '::after': { // Use a pseudo-element to create the holes
                            //     content: '""',
                            //     position: 'absolute',
                            //     left: '-8px', // Position outside the left edge
                            //     top: '20px',  // Start the holes a bit below the top border
                            //     bottom: '20px', // Extend the holes almost to the bottom
                            //     width: '20px',
                            //     background: 'radial-gradient(circle, #fff 20%, transparent 20%)', // White circle with transparent edges
                            //     backgroundRepeat: 'repeat-y', // Repeat the holes vertically
                            //     backgroundSize: '10px 10px', // Adjust the size of each hole
                            //     zIndex: '100',
                            // }


                        }}>


                            <div style={{
                                margin: '0rem', // This centers the div horizontally with equal margins on both sides (5% each)
                            }}>

                                {parse(lesson_data.html_note_content)}

                            </div>


                        </div>




                    </div>

















*/