import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
import * as populateTopicPosts from "../../redux/pagemodules/community/populateTopicPosts.js"
import { COMMUNITY_TOPIC_DATA } from '../../redux/selectors';

import TESTINGDropdownMinimal from './TESTINGDropdownMinimal'



import { useState, useEffect } from "react";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";




import CONFIG from '../../config/config.js'


import ReplyThreadComponent from './ReplyThreadComponent'















const StyledAddIcon = () => (
    <div style={{
        width: '100%', display: 'flex', justifyContent: 'center',
        padding: '0rem', margin: '0rem',
    }}>
        <Add
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: '#a467cc', margin: '0rem',
            }}
        >
        </Add>
    </div>
);

const StyledRemoveIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Remove
            sx={{
                mx: 2, width: '2rem', height: '2rem',
                color: '#a467cc',
                padding: '0rem', margin: '0rem',
            }}
        >
        </Remove>
    </div>
);
























const RepliesDisplay = (props) => {
    // Extract data from props
    const { data } = props;

    console.log('RepliesDisplay ', data)
    console.log('childElementArray ', data.childElementArray.length)
    const numberChildElements = data.childElementArray.length
    let showRepliesComponent = null
    if (numberChildElements > 0) {
        showRepliesComponent = true
    } else {
        showRepliesComponent = false
    }




    console.log('childElementArray ', data.childElementArray.length)

    const [openStatus, setOpenStatus] = useState(false);
    const [postRepliesObject, setPostRepliesObject] = useState({ childElementArray: [] });


    useEffect(() => {
        setPostRepliesObject(props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR.postReplies[data._id])
    }, [props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR.postReplies]);




    const handleclick = (item) => {

        console.log('click')
        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP
        let fetchObject = {
            itemId: item._id,
            url: fetchEndpointURL
        }
        setOpenStatus(!openStatus)
        populateTopicPosts.fetchPostReplies(fetchObject)
        console.log('postRepliesObject ', postRepliesObject, props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR.postReplies)
    }





    // {postRepliesObject ? ( ) : ()}


// repliesdisplay -> ReplyThreadComponent

const handleCallback = (dataFromChild) => {
    // Do something with the data received from the child component
    console.log("Data from child:", dataFromChild);
    // handleclick(dataFromChild)

    let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP
    let fetchObject = {
        itemId: dataFromChild._id,
        url: fetchEndpointURL
    }
    populateTopicPosts.fetchPostReplies(fetchObject)
    console.log('postRepliesObject ', postRepliesObject, props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR.postReplies)


  };




  




    return (

        <div>
            {showRepliesComponent ? (




                <div
                    style={{
                        margin: '0rem',
                        padding: '0rem',
                    }}
                >
                    <style>
                        {`
            .fade {
                opacity: 1;
                transition: opacity 0.3s ease;
            }
            .visible {
                opacity: 1;
            }
            .hidden {
                opacity: 0;
            }
        `}
                    </style>

                    <div sx={{
                        //minWidth: 300,
                        backgroundColor: '#eefffc', // 'white', //'#97cbc2',
                        // color: MENU_TEXT_COLOR,
                        borderRadius: '0px',
                        opacity: '100%',
                        margin: '0rem',
                        //marginTop: '0.5rem',
                        padding: '0rem',
                        borderColor: 'black',
                        borderRadius: '0.5rem',
                        fontSize: '1rem',
                        boxShadow: '2px 2px 5px rgba(03, 44, 88 0.5)'
                    }}>







                        <CardHeader
                            onClick={() => handleclick(data)}


                            // THIS WAS THE ELEMEND MAKING THE THING TOO BIG
                            sx={{ padding: '0.5rem', }}

                            aria-label="expand"
                            size="medium"

                            titleTypographyProps={{
                                fontFamily: 'imported-font-varela',
                                fontSize: "1rem", fontWeight: 'bold', textAlign: 'left',
                            }}
                            title='View Replies'
                            action={
                                <IconButton
                                    //onClick={() => setOpenStatus(!openStatus)}
                                    aria-label="expand"
                                    size="small"
                                // sx={{
                                //     width: '4rem',
                                //     height: '4rem',
                                // }}
                                >
                                    {openStatus ? <StyledRemoveIcon />
                                        : <StyledAddIcon />}

                                </IconButton>
                            }
                        >
                        </CardHeader>







                        <div style={{
                            padding: '0rem', margin: '0rem',
                        }}>

                            <Collapse in={openStatus} timeout="auto"
                                unmountOnExit

                                style={{
                                    padding: '0rem', margin: '0rem', border: '0rem'
                                }}

                            >
                                <CardContent
                                    style={{
                                        padding: '0rem', margin: '0rem', border: '0rem',
                                    }}
                                >
                                    <Container

                                        sx={{
                                            height: 'auto',
                                            width: '100%',
                                            padding: '0rem',
                                            margin: '0rem',
                                        }}>

                                        <div
                                        // style={{
                                        //     padding: '1rem',
                                        //     paddingTop: '0rem',
                                        // }}
                                        >










                                            {postRepliesObject ? (
                                                <div>
                                                    <ReplyThreadComponent data={postRepliesObject} recursionLevel={0} onCallback={handleCallback} ></ReplyThreadComponent>
                                                </div>
                                            ) : (
                                                <div>
                                                    
                                                </div>
                                            )}
















                                        </div>
                                    </Container>
                                </CardContent>
                            </Collapse>
                        </div>
                    </div>
                </div >




            ) : (
                <div></div>
            )}

        </div>



    );
};





















//     // Extract data from props
//     const { data } = props;
//     //  console.log('RepliesDisplay ', data)

//     const  handleClick = () => {
//         console.log('click')
//     }



//     return (
//         <div
//         onClick={() => handleClick()}
//         >

//             {/* <Card
//                 style={{
//                     margin: '0rem',
//                     padding: '0.5rem',
//                     backgroundColor: '#97cbc2',
//                     borderRadius: '0.5rem',
//                 }}
//             >
//                 VIEW REPLIES 2
//             </Card> */}


//             <TESTINGDropdownMinimal>

//             </TESTINGDropdownMinimal>




//         </div >
//     );
// };












const mapStateToProps = (state) => ({
    COMMUNITY_TOPIC_DATA_FROM_SELECTOR: COMMUNITY_TOPIC_DATA(state),
});

export default connect(mapStateToProps)(RepliesDisplay);




