import React, { useState } from 'react';
import ModalComponent from './ModalComponent'; // Import your custom modal
import ModalContent from './ModalContent'; // Import the content component

const ModalPageComponent = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };




    function toggleModal() {
        setModalIsOpen(!modalIsOpen);
    }

    // React.useImperativeHandle(ref, () => ({
    //     childFunction
    // }));

    // function childFunction() {
    //     toggleModal()
    // }















    return (
        <div>



            {/* <button onClick={openModal}>Open Modal</button> */}



            <div onClick={() => openModal()}
                style={{
                    marginTop: '3rem',
                    marginBottom: '3rem',
                    padding: '1rem',
                    backgroundColor: '#97CBC2',
                    width: '9rem',
                    height: '9rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    borderRadius: '1rem',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <button
                        style={{
                            backgroundColor: '#97CBC2',
                            border: 'none',
                        }}
                    >
                        {/* <img style={{
                                        height: '6rem',
                                        width: '6rem',
                                        align: 'center',
                                        backgroundColor: '#97CBC2',
                                    }}
                                        src={courseLogo} /> */}
                    </button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <p style={{
                        fontWeight: 'bold', textAlign: 'center',
                    }}>Open Modal</p>
                </div>
            </div>






            <ModalComponent
                isOpen={modalIsOpen}
                onRequestClose={closeModal}

            >
                <ModalContent onRequestClose={closeModal}></ModalContent>

            </ModalComponent>

















        </div>
    );
};

export default ModalPageComponent;
