import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';


import exampleImage_1 from "./example_1.png"


const Podcast = () => {

    const { isAuthenticated, user, loading } = useSelector(state => state.user);






    const iframeString = `<iframe 
    title="The Birth Lounge Podcast" allowtransparency="true"  
    style="border: none; min-width: min(100%);height:600px;" scrolling="yes" data-name="pb-iframe-player" 
    src="https://www.podbean.com/player-v2/?i=7tz35-25c03f-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=666666&font-color=&logo_link=episode_page&order=episodic&limit=10&filter=all&ss=a713390a017602015775e868a2cf26b0&btn-skin=2baf9e" 
    loading="lazy" allowfullscreen=""></iframe>`
    
    
    //"https://www.podbean.com/player-v2/?i=7tz35-25c03f-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=666666&font-color=&logo_link=episode_page&order=episodic&limit=10&filter=all&ss=a713390a017602015775e868a2cf26b0&btn-skin=2baf9e"



    const iframeHTML = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
    </head>
    <body>
        <div  
    id="baseDiv">${iframeString}</div>
    </body>
    </html>
    `;




    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;


    return (
        <Layout title='The Birth Lounge' content='Podcast page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>
                    {/* <h3> Podcast</h3> */}


                    <div style={{
                        paddingBottom: '1rem',

                    }}>
                        <h3
                            style={{
                                fontSize: '2rem',
                                fontWeight: 'bold',
                            }}
                        >Podcast</h3>
                    </div>



                    <div style={{ height:'100vh', fontSize: '1.3rem', padding: '0rem' }}>


                        <iframe
                            srcDoc={iframeHTML} // Use srcDoc to embed the HTML directly
                            width={'100%'}
                            height={'100%'}
                            style={{ border: 'none' }} // Optionally remove the default border
                        />


                    </div>







                    <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
                        <p>


                            Podcast:-when you click the “microphone” icon, a menu will pop up that offers: listen to TBL podcast, hear HeHe on guest podcasts


                        </p>
                    </div>




                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '18rem',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}
                            src={exampleImage_1}></img>

                    </div>



                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

export default Podcast;
