import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'features/user';
//import defaultReducer from './reducers/defaultReducer';
import r01_LibraryReducer from './reducers/r01_LibraryReducer';
import r02_CourseSelectReducer from './reducers/r02_CourseSelectReducer';
import r03_LessonSelectReducer from './reducers/r03_LessonSelectReducer';
import r05_CommunityTopicReducer from './reducers/r05_CommunityTopicReducer'


const initialState = {
	// user: null,
	// library_data: null,
	// selected_course: null,
	// selected_lesson: {
	// 	selectedLesson: {
	// 		id: 2,
	// 		lesson_title: "22",
	// 		content: "test",
	// 	}

	// },
	community_topics: {
		communityTopics: null,
		topicPosts: null,
		postReplies: {},
	}
};



export const store = configureStore({

	reducer: {
		user: userReducer,
		library_data: r01_LibraryReducer,
		selected_course: r02_CourseSelectReducer,
		selected_lesson: r03_LessonSelectReducer,

		community_topics: r05_CommunityTopicReducer,

		
	},
	preloadedState: initialState, // Pass the initial state here
	devTools: process.env.NODE_ENV !== 'production',
	//devTools: process.env.NODE_ENV == 'production',

});


// const store = configureStore({
// 	reducer: {
// 		user: userReducer,
// 		default: defaultReducer,
// 	},
// 	devTools: process.env.NODE_ENV !== 'production',
// });


// export default store;













// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './reducers/rootReducer'; // import the root reducer
// import thunkMiddleware from 'redux-thunk';

// const initialState = {
//   full_wine_data: [],
//   data_search_objects: [],
//   selected_search_category: [],
//   wine_search_results: {
//     wineSearchResults: [],
//     wineSearchResultsLength: 0,
//   },
//   full_drink_data: [],
//   favorite_items: {
//     itemData: []
//   },
// };

// const store = configureStore({
//   reducer: rootReducer,
//   preloadedState: initialState, // Pass the initial state here
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       thunk: {
//         extraArgument: thunkMiddleware
//       }
//     })
// })

// export default store;
