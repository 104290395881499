// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 



// REDUX
import { store } from '../../store.js';


// REDUX ACTIONS
import a06_FetchPosts from "../../actions/a06_FetchPosts"
import a07_FetchPostReplies from "../../actions/a07_FetchPostReplies"

// a06_FetchPosts.js

//import a04_FetchUserAccessSuccess from "../../actions/a04_FetchUserAccessSuccess"
// let fetchEndpointURL = '/api/getcourses'

const devModeBool = true

export const fetchPosts = async (fetchObject) => {

    if (devModeBool === true) {
        console.log('Component mounted, if this happens twice check strict mode');
    }

    let itemId = fetchObject.itemId
    let fetchURL = fetchObject.url + '/api/community/getTopicPosts'
    //let fetchURL = 'http://localhost:5000/api/community/getTopicPosts'
    console.log('itemId ', itemId)
    console.log('fetchURL ', fetchURL)
    let topicPosts = itemId

    try {
        console.log(fetchURL )
        const response = await fetch(fetchURL, { // Adjust the API endpoint as needed
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ topicPosts })

        })
        .then(response => response.json())
        .then(data => {
            console.log('Response data:', data); // Log the JSON data here
            store.dispatch(a06_FetchPosts(data));
        });
        // console.log('response' , response)
        //await store.dispatch(a06_FetchPosts(response));


        // const response = await fetch(fetchEndpointURL);
        // const data = await response.json();
        // console.log('data ', data)
        // await store.dispatch(a06_FetchPosts(data));

    } catch (error) {
        console.error('Error:', error);
    }

}













export const fetchPostReplies = async (fetchObject) => {

    if (devModeBool === true) {
        console.log('Component mounted, if this happens twice check strict mode');
    }

    let itemId = fetchObject.itemId
    let fetchURL = fetchObject.url + '/api/community/getThreadByKey'
    console.log('itemId ', itemId)
    console.log('fetchURL ', fetchURL)
    //let topicPosts = itemId

    try {

        const response = await fetch(fetchURL, { // Adjust the API endpoint as needed
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ itemId })

        })
        .then(response => response.json())
        .then(data => {
            console.log('Response data:', data); // Log the JSON data here

            let reducerPayloadObject = {
                postId: itemId,
                data: data,
            }

            store.dispatch(a07_FetchPostReplies(reducerPayloadObject));
        });



        // console.log('response' , response)
        //await store.dispatch(a06_FetchPosts(response));


        // const response = await fetch(fetchEndpointURL);
        // const data = await response.json();
        // console.log('data ', data)
        // await store.dispatch(a06_FetchPosts(data));




    } catch (error) {
        console.error('Error:', error);
    }

}












// async function getTopicPosts() {

//     const topicPosts = document.getElementById('getTopicPostsText').value;
//     console.log('topicPosts',topicPosts)
//     const response = await fetch('/api/community/getTopicPosts', { // Adjust the API endpoint as needed
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ topicPosts })

//     })
//         .then(response => response.json())
//         .then(data => {
//             console.log('Response data:', data); // Log the JSON data here
//         });
//     console.log('gettopiccs')
// }








