import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';


import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';
import { useNavigate } from 'react-router-dom';


import{ useState, useEffect } from 'react';






// THIS PAGE DISPLAYS AT THE DEFAULT URL action="http://localhost:4242/create-checkout-session"  prod_QTU0eIuTxC3vz4  price_1PdNIrKE0e1CFeGpgTKHfc1U
// https://birthlounge.dev/stripe/create-checkout-session
const ProductDisplay = () => (
    <div>
      <form action="http://localhost:4242/create-checkout-session" method="POST">
        <input type="hidden" name="lookup_key" value='price_1PdNIrKE0e1CFeGpgTKHfc1U' />
        <button id="checkout-and-portal-button" type="submit">
          Checkout
        </button>
      </form>
    </div>
  
  );
















  const SuccessDisplay = ({ sessionId }) => {
    return (
      <section>
        <div className="product Box-root">
          {/* <Logo />

          http://localhost:4242/create-portal-session
          
          */}
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form action="https://birthlounge.dev/stripe/create-portal-session" method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </section>
    );
  };
  
  






















const StripePage = (props) => {






    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
  
  
  
    useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
  
      if (query.get('success')) {
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }
  
      if (query.get('canceled')) {
        setSuccess(false);
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
      }
    }, [sessionId]);










    if (!success && message === '') {
        return <ProductDisplay />;
      } else {
        return (
            <div>
              <SuccessDisplay sessionId={sessionId} />;
            </div>
        );
      }






      // if (!success && message === '') {
      //   return <ProductDisplay />;
      // } else if (success && sessionId !== '') {
      //   return <SuccessDisplay sessionId={sessionId} />;
      // } else {
      //   return <Message message={message} />;
      // }
    















    // return (

    //     <Layout title='The Birth Lounge' content='Stripe page'>

    //             <div>




    //                 <div style={{
    //                     display: 'grid',
    //                     gridTemplateColumns: '1fr 1fr',
    //                     gridTemplateRows: 'auto auto 1fr',
    //                     margin: '0',
    //                     height: '100vh'
    //                 }}>



    //                 </div>

    //                 <div style={{
    //                     paddingBottom: '10rem',
    //                 }}>

    //                 </div>
    //                 <BottomBar></BottomBar>

    //             </div>
    //     </Layout>
    // );





};

export default StripePage;

