

export default function a03_SelectLesson (data) {
    console.log('SELECT_LESSON')
    return {
      type: 'SELECT_LESSON',
      payload: data
    };
  };



