

export default function a05_FetchCommunityTopicSuccess (data) {
    console.log('FETCH_COMMUNITY_TOPIC_SUCCESS')
    return {
      type: 'FETCH_COMMUNITY_TOPIC_SUCCESS',
      payload: data
    };
  };


  