


export default function a04_FetchUserAccessSuccess (data) {
    console.log('FETCH_USER_ACCESS_SUCCESS')
    return {
      type: 'FETCH_USER_ACCESS_SUCCESS',
      payload: data
    };
  };
