
const CONFIG = {

    // ssh sysadmin-01@134.209.169.98
    // configurationManagementUser2024!
    // rsync -av --progress --exclude 'node_modules' ./* sysadmin-01@134.209.169.98:/home/sysadmin-01/mongo/0829
    
    // 

    //   rsync -av --progress --exclude 'node_modules' ./build sysadmin-01@134.209.169.98:/home/sysadmin-01/0724/frontend/client/transfer
    
    
    
    //COMMUNITY_SERVER_IP: 'http://192.168.5.118:4200'


    COMMUNITY_SERVER_IP: ''

    //COMMUNITY_SERVER_IP: 'http://192.168.5.118:5000'
    
    //COMMUNITY_SERVER_IP: 'https://www.birthlounge.dev'

}

export default CONFIG;
