import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';


import exampleImage_1 from "./example_1.png"
import exampleImage_2 from "./example_2.png"

import { useState, useEffect, useRef } from "react";
import CONFIG from '../../config/config.js'


// REDUX
import { connect } from 'react-redux';
// REDUX OPERATION MODULES
import * as initializeOperations from "../../redux/pagemodules/community/initializeCommunity.js"
import { COMMUNITY_TOPIC_DATA } from '../../redux/selectors';



import CommunityDisplay from './CommunityDisplay'














const Community = (props) => {

    const { isAuthenticated, user, loading } = useSelector(state => state.user);





    //const response = await fetch(fetchEndpointURL.course_url);

    const handleButtonClick = async (pathURL) => {
        console.log('test', pathURL)
        await fetch(pathURL);
    };





    const [pageloading, setPageLoading] = useState(true);
    // const fetchDataAfterPageLoad = async (fetchEndpointURL) => {
    //     try {
    //         const response = await fetch(fetchEndpointURL);
    //         const data = await response.json();
    //         console.log('data ', data)
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // }

    useEffect(() => {
        let fetchEndpointURL = CONFIG.COMMUNITY_SERVER_IP + '/api/community/getTopics'
        const initializeFetch = async () => {
            if (pageloading === true) {
                await initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)
                setPageLoading(false);
            }
        };
        initializeFetch();
    }, []);

















    if (pageloading) {
        return <div></div>;
    } else if (!isAuthenticated && !loading && user === null) {
        return <Navigate to="/login" />;
    } else {

        return (
            <Layout title='The Birth Lounge' content='Community page'>
                {loading || user === null ? (
                    <div className='spinner-border text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </div>
                ) : (
                    <div

                        style={{
                            backgroundColor: '#ffffff'
                        }}

                    >
                        {/* <h3>Community</h3> */}







                        {/* <div
                            onClick={() => handleButtonClick("/api/test")}
                            style={{
                                backgroundColor: "#97CBC2",
                                width: "9rem",
                                height: "3rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "1rem",
                                cursor: "pointer",
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                    margin: "0", // Remove padding for better vertical centering
                                }}
                            >
                                TEST
                            </p>
                        </div> */}


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%'
                            }}
                        >
                            <div

                                style={{
                                    maxWidth: '600px',
                                }}

                            >




                                <div style={{
                                    //textAlign: 'center',
                                    paddingBottom: '1rem',
                                    //fontFamily: 'imported-font-varela',

                                }}>
                                    <h3
                                        style={{
                                            fontSize: '2rem',
                                            fontWeight: 'bold',
                                        }}
                                    >Community</h3>
                                </div>




                                <CommunityDisplay data={props.COMMUNITY_TOPIC_DATA_FROM_SELECTOR} ></CommunityDisplay>
                            </div>

                        </div>



                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                            marginTop: '25rem',
                        }}>
                            <img style={{
                                width: '18rem',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '5rem',
                            }}
                                src={exampleImage_1}></img>

                        </div>


                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '5rem',
                        }}>
                            <img style={{
                                width: '18rem',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '5rem',
                            }}
                                src={exampleImage_2}></img>

                        </div>



                        <BottomBar></BottomBar>

                    </div>
                )}
            </Layout>
        );
    }
};

//export default Community;

const mapStateToProps = (state) => ({
    COMMUNITY_TOPIC_DATA_FROM_SELECTOR: COMMUNITY_TOPIC_DATA(state),
});

export default connect(mapStateToProps)(Community);











// <div style={{ fontSize: '1.3rem', padding: '1rem' }}>
// <p>

// This could be built from scratch or there are free open source options available, 
// such as Misago (https://misago-project.org/)
// which is a "modern, fully featured forum application written in Python and ES6, powered by Django and React. js"
// </p>
// </div>










// <div style={{ fontSize: '1.7rem', padding: '1rem' }}>
// <p>


//     Communities:-when you click the “text box” icon, a menu will pop up that offers: the communities this person is in


// </p>
// </div>






// <div style={{
// width: '100%',
// display: 'flex',
// justifyContent: 'center',
// paddingBottom: '5rem',
// }}>
// <img style={{
//     width: '18rem',
//     display: 'flex',
//     justifyContent: 'center',
//     paddingBottom: '5rem',
// }}
//     src={exampleImage_1}></img>

// </div>


// <div style={{
// width: '100%',
// display: 'flex',
// justifyContent: 'center',
// paddingBottom: '5rem',
// }}>
// <img style={{
//     width: '18rem',
//     display: 'flex',
//     justifyContent: 'center',
//     paddingBottom: '5rem',
// }}
//     src={exampleImage_2}></img>

// </div>





// const fetchDataAfterPageLoad = async (fetchEndpointURL) => {

//     try {

//         const response = await fetch(fetchEndpointURL.course_url);
//         const data = await response.json();
//        console.log('data ', data)

//     } catch (error) {
//             console.error('Error:', error);
//     }

// }














