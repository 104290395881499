

export default function exportedFunction(state = {}, action) {

    if (action.type === 'FETCH_LIBRARY_DATA_SUCCESS') {

        console.log('FETCH_LIBRARY_DATA_SUCCESS', action.payload)

        let courseListArray = action.payload

        return {
            courseListArray: courseListArray,
            //userAccessArray: null,
        };
    }


    if (action.type === 'FETCH_USER_ACCESS_SUCCESS') {

        console.log('FETCH_USER_ACCESS_SUCCESS', action.payload)

        let courseListArray = action.payload.course
        console.log(typeof courseListArray); 
        let subscriptionPlans = action.payload.subscription_plans

        for (let element of courseListArray) {
            //// console.log('element ', element); // Output: 1 2 3 4 5
            if (element.course_access == 'true'){
                element.course_access = true
            } else {
                element.course_access = false
            }
          }

        return {
            courseListArray: courseListArray,
            subscriptionPlans: subscriptionPlans,
            //userAccessArray: "FETCH_USER_ACCESS_SUCCESS",
        };
    }


    return state;
}


