

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import CourseDetails from './CourseDetails';


const LibraryCourseList = (props) => {

    // Extract data from props
    const { data } = props;
    let renderDisplayItems = data.courseListArray
    console.log('renderDisplayItems', renderDisplayItems)
    if (renderDisplayItems === undefined) { renderDisplayItems = [] }

    return (
        <div style={{ width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                flexWrap: "wrap",
                //borderWidth: '2px',
                //borderColor: '#edece8',
                //borderStyle: 'solid',
                //paddingBottom: '1rem',
                marginBottom: '1rem',
            }}>
                <div style={{
                    width: '100%', height: 'auto'
                }}
                >



                    {
                        renderDisplayItems &&
                        renderDisplayItems.length > 0 &&
                        renderDisplayItems.map((item, index) => (

                            <CourseDetails

                                key={`displayItem_${index}`}
                                id={index + 1}

                                course_id={item.id}
                                courseTitle={item.course_title}
                                data_description={item.course_description}
                                logoIndex={item.course_logo}
                                isLocked={!item.course_access}
                                subscription_plans={item.subscription_plans}
                            ></CourseDetails>



                            // <ResultItem
                            //     key={`displayItem_${index}`}
                            //     id={index + 1}
                            // data={item}
                            // ></ResultItem>
                            // <div>{item.course_title}</div>



                        ))}





                </div>


            </div>
        </div >
    );
};

export default LibraryCourseList;
