

import Navbar from 'components/Navbar2';

const Layout = ({ title, content, children }) => (
	<div style={{ backgroundColor: '#73AFA4', minHeight: '120vh' }} >

		<Navbar />
		<div className='container mt-5' style={{ backgroundColor: '#73AFA4' }}>{children}</div>
	</div>
);

export default Layout;


