

export default function exportedFunction(state = {}, action) {

    if (action.type === 'SELECT_COURSE') {

        console.log('SELECT_COURSE REDUCER', action.payload)

        let selectedCourse = action.payload.responseData
        let courseObject = action.payload.courseObject
        let subscription_plans = action.payload.subscription_plans

        return {
            selectedCourse: selectedCourse,
            courseObject: courseObject,
            subscription_plans: subscription_plans,
        };
    }

    return state;
}

