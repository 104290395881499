



export default function a02_SelectCourse (data) {
    console.log('SELECT_COURSE')
    return {
      type: 'SELECT_COURSE',
      payload: data
    };
  };


  