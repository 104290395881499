import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import BottomBar from '../../components/BottomBar';



import ModalPageComponent from './ModalPageComponent'



import modal_1 from './modal_1.png';

import { useNavigate } from 'react-router-dom';

const SampleModalPage = () => {


    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        navigate(pathURL); // Replace with your desired route
    };


    const { isAuthenticated, user, loading } = useSelector(state => state.user);

    if (!isAuthenticated && !loading && user === null)
        return <Navigate to='/login' />;

    return (
        <Layout title='The Birth Lounge' content='Template page'>
            {loading || user === null ? (
                <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            ) : (
                <div>


                    <div
                        onClick={() => handleButtonClick("/home")}
                        style={{
                            backgroundColor: "#97CBC2",
                            width: "6rem",
                            height: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "1rem",
                            cursor: "pointer",
                            marginBottom: '2rem',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "bold",
                                margin: "0", // Remove padding for better vertical centering
                            }}
                        >
                            Back
                        </p>
                    </div>


                    <h3 style={{ textAlign: 'center', }}>Sample Modal Page</h3>



                    <ModalPageComponent
                    ></ModalPageComponent>








                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '5rem',
                    }}>
                        <img style={{
                            width: '18rem',
                            align: 'center',
                        }}
                            src={modal_1}></img>
                    </div>







                    <div style={{
                        paddingBottom: '5rem',
                    }}></div>





                    <BottomBar></BottomBar>

                </div>
            )}
        </Layout>
    );
};

export default SampleModalPage;
