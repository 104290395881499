

import React from 'react';
import pdfFile from "./download/design_document.pdf"
import download_icon from './download_icon.png'

const ModalContent = (props) => {

  const { onRequestClose } = props;
  function closeModal() {
    onRequestClose()
  }


  return (
    <div >
      <h2
        style={{
          textAlign: 'center',
        }}
      >
        Modal Title
      </h2>

      {/* <p
        style={{
          textAlign: 'center',
          margin: '3rem',
        }}
      >
        Modal Contents
      </p> */}


















      <div

        style={{

          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginTop:'3rem',
          marginBottom:'1rem',
        }}
      >
        <div
          style={{

            width: '6rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >

          <a href={pdfFile} download
            style={{
              fontSize: '1.8rem',
              backgroundColor: '#97CBC2',
              padding: '1rem',
              borderRadius: '1rem',
              color: 'black',
            }}>
            <img src={download_icon}></img>

          </a>

        </div>

      </div>
      <p style={{ textAlign: 'center', fontSize:'1.5rem' }}>Download the Design Document PDF</p>

























      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >

        <button
          onClick={closeModal}
          style={{
            fontSize: '1.1rem',
            marginTop: '4rem',
            padding: '1rem',
            border: 'none',
            borderRadius: '1rem',
            backgroundColor: '#BBE7E0',
          }}
        >Close
        </button>


      </div>


















    </div>
  );
};

export default ModalContent;











































// import React from 'react';
// import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';






// const ModalContents = (props) => {

//     const { onRequestClose } = props;





//     function closeModal() {
//         onRequestClose()
//     }


















//     return (
//         <div
//             style={{
//                 width: '18rem',
//                 minHeight: '35rem',
//                 height: '100%',
//                 opacity: '100%',
//                 backgroundColor: '#edece8',
//                 // backgroundColor: '#edece8', //'#ccdde8', edece8
//                 border: '0px',
//                 margin: '0rem',
//                 padding: '10px',
//             }}
//         >















//             <div
//                 style={{
//                     margin: '0px',
//                 }}
//             >





//             </div>





//             <div
//                 style={{
//                     display: "flex",
//                     flexDirection: 'row',
//                     height: '35rem',
//                     paddingBottom: '1rem',
//                     paddingTop: '0rem',
//                     paddingLeft: '0rem',
//                     paddingRight: '0rem',
//                 }}>


//                 <div
//                     style={{
//                         padding: '0rem',
//                         margin: '0rem',
//                         width: '100%',
//                         // backgroundColor: '#fff',
//                     }}>




//                     <Box sx={{
//                         //position: "absolute",
//                         backgroundColor: '#034488',
//                         padding: '0px',
//                         width: '100%', //'9rem',
//                         height: '27rem',
//                         margin: '0px',
//                     }}
//                     >
//                         <Box
//                             sx={{
//                                 //position: "absolute",
//                                 padding: '0rem',
//                                 backgroundColor: 'white',
//                                 width: '100%',
//                                 height: '35rem',
//                                 border: '5px',
//                                 borderColor: 'black',
//                                 //paddingBottom: '12px',
//                                 backgroundSize: 'contain', // 'cover',
//                                 backgroundRepeat: 'no-repeat', 
//                                 backgroundPosition: 'center',
//                                 // backdropFilter: 'blur(1px)', // Adjust the blur amount as needed
//                                 // opacity: 0.8, // Adjust the opacity to control the intensity of the background
//                                 minHeight: '10rem',
//                                 animation: 'fade-in 0.3s forwards',
//                                 animationDelay: '0s', // Adjust the delay as needed


//                             }}
//                         >
//                         </Box>
//                     </Box>


//                 </div>



//             </div>





//             <div
//                 style={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'space-between',
//                 }}>



//                 <button
//                     onClick={closeModal}
//                     style={{
//                         opacity: '90%',
//                         backgroundColor: '#011552', // '#ccdde8',
//                         color: 'white', // '#034488', //'rgb(171,18,57)', //'maroon',
//                         fontSize: '1.1rem',
//                         // fontSize: '1.3rem',
//                         padding: '0.5rem',
//                         padding: '1rem',
//                         margin: '0rem',
//                         border: '0rem',
//                         fontWeight: 'bold',
//                         width: '100%',
//                         color: 'black',
//                     }}
//                 >CLOSE IMAGE
//                 </button>

//             </div>





















//         </div >
//     );
// };

// export default ModalContents;




