

// import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';

const Layout = ({ title, content, children }) => (
	<div 
	//style={{ backgroundColor: '#73AFA4', minHeight: '120vh' 
		// style={{backgroundColor:'#e7f0f1', }}

		style={{
			backgroundColor: '#ffffff'
		}}

	 >


		{/* 
		73AFA4 - darker
		97CBC2 - lighter
		<Helmet style={{backgroundColor:'#73AFA4'}}>
			<title>{title}</title>
			<meta name='description' content={content} />
		</Helmet> */}


		<Navbar />
		<div className='container mt-3' 
		//style={{ backgroundColor: '#73AFA4'}}
		//style={{backgroundColor:'#e7f0f1', }}
		>{children}</div>
	</div>
);

export default Layout;








// import { Helmet } from 'react-helmet';
// import Navbar from 'components/Navbar';

// const Layout = ({ title, content, children }) => (
// 	<>
// 		<Helmet>
// 			<title>{title}</title>
// 			<meta name='description' content={content} />
// 		</Helmet>
// 		<Navbar />
// 		<div className='container mt-5'>{children}</div>
// 	</>
// );

// export default Layout;
