import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from 'components/Layout';






import { useNavigate } from 'react-router-dom';
import discussion_icon from './svg/discussion_icon.svg'
import notes_icon from './svg/notes_icon.svg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import podcast_icon from './svg/podcast_icon.svg'
import support_icon from './svg/support_icon.png'


import home_icon from './svg/home_icon.png'















const BottomBar = () => {

    // Extract data from props
    // const { data } = props;



    const navigate = useNavigate();

    const handleButtonClick = (pathURL) => {
        // navigate('/library'); // Replace with your desired route
        navigate(pathURL); // Replace with your desired route
    };




















    return (
        <div>



            <style>
                {`

                            .bottom-bar {
                                position: fixed;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                background-color: #E7F4F0; /* Or your preferred background  */ 
                                padding: 5px;
                                box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                            }
                            
                            .bottom-bar button {
                                background-color: #BBE7E0;
                                border: none;
                                padding: 0px;
                                height:4rem;
                                width:4rem;
                                border-radius:2rem;
                                cursor: pointer;
                            }

                            .bottom-bar button:hover {
                                background-color: #97CBC2;
                                border: none;

                            }

                            .bottom-bar button:active {
                                background-color: #73AFA4;
                                border: none;

                            }
                            .bottom-bar p {
                                color: #9B59B6;
                                // font-weight: bold;
                                border: none;
                                padding: 0px;
                                margin: 0px;
                                font-size: 0.7rem;
                                cursor: pointer;
                                text-align: center;
                            }

                            .bottom-bar img {
                                height:2.5rem;
                                width:2.5rem;
                            }



                        `}
            </style>





            <div>


                <div className="bottom-bar">


                    <div onClick={() => handleButtonClick('/community')} >
                        <button>
                            <img src={discussion_icon}
                                style={{
                                    height: '3rem',
                                    width: '3rem',
                                }}
                            />
                        </button>
                        <p>Community</p>
                    </div>

                    <div onClick={() => handleButtonClick('/library')} >
                        <button>
                            <img src={notes_icon}
                            />
                        </button>
                        <p>Library</p>
                    </div>






                    {/* <div  onClick={() => handleButtonClick('/home')} >
                                <button>
                                
                                <HomeOutlinedIcon sx={{height:'4rem', width: '4rem'}}></HomeOutlinedIcon>
                                </button>
                                <p>Home</p>
                            </div> */}




                    <div onClick={() => handleButtonClick('/home')} >
                        <button>
                            <img src={home_icon} />
                        </button>
                        <p>Home</p>
                    </div>




                    <div onClick={() => handleButtonClick('/podcast')} >
                        <button>
                            <img src={podcast_icon} />
                        </button>
                        <p>Podcast</p>
                    </div>

                    <div onClick={() => handleButtonClick('/support')} >
                        <button>
                            <img src={support_icon} />
                        </button>
                        <p>Support</p>
                    </div>
                </div>



            </div>




        </div >
    );
};

export default BottomBar;

