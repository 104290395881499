

export default function exportedFunction(state = {}, action) {

    if (action.type === 'SELECT_LESSON') {

        console.log('SELECT_LESSON REDUCER', action.payload)

        let selectedLesson = action.payload

        return {
            selectedLesson: selectedLesson,
            // lesson_module: null,
            // lesson_course: null,
        };
    }

    return state;
}

